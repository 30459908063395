import Image from 'next/image'
import Executing from '@/assets/img/icons/swarm_executing_pink_32x26.svg'
import Defining from '@/assets/img/icons/swarm_defining_yello_32x26.svg'
import Evaluating from '@/assets/img/icons/swarm_evaluating_green_32x26.svg'

export const category = {
  1: (
    <div className="flex justify-start gap-2 lg:gap-3 items-center">
      <Image src={Evaluating} alt="Evaluating" className="green_shadow" />
      <p className="text-xs leading-normal md:text-base lg:text-lg text-[16px] font-bold">
        {' '}
        Active <span className="text-[#E93249]">Keyword</span> Tracking{' '}
      </p>
    </div>
  ),
  2: (
    <div className="flex justify-start gap-2 lg:gap-3 items-center">
      <Image src={Evaluating} alt="Evaluating" className="green_shadow" />
      <p className="text-xs leading-normal md:text-base lg:text-lg text-[16px] font-bold">
        {' '}
        Active <span className="text-[#E93249]">Narrative</span> Tracking{' '}
      </p>
    </div>
  ),
  3: (
    <div className="flex justify-start gap-2 lg:gap-3 items-center">
      <Image src={Evaluating} alt="Defining" className="green_shadow" />
      <p className="text-xs leading-normal md:text-base lg:text-lg text-[16px] font-bold">
        {' '}
        Inactive <span className="text-[#E93249]">Keyword</span> Tracking{' '}
      </p>
    </div>
  ),
  4: (
    <div className="flex justify-start gap-2 lg:gap-3 items-center">
      <Image src={Executing} alt="Executing" className="pink_shadow" />
      <p className="text-xs leading-normal md:text-base lg:text-lg text-[16px] font-bold">
        {' '}
        Inactive <span className="text-[#E93249]">Narrative</span> Tracking{' '}
      </p>
    </div>
  ),
  5: (
    <div className="flex justify-start gap-2 lg:gap-3 items-center">
      <Image src={Defining} alt="Defining" className="orange_shadow" />
      <p className="text-xs leading-normal md:text-base lg:text-lg text-[16px] font-bold">
        {' '}
        Active Smart Chats{' '}
      </p>
    </div>
  ),
  6: (
    <div className="flex justify-start gap-2 lg:gap-3 items-center">
      <Image src={Defining} alt="Defining" className="orange_shadow" />
      <p className="text-xs leading-normal md:text-base lg:text-lg text-[16px] font-bold">
        {' '}
        Surveillance Suggestions{' '}
      </p>
    </div>
  ),
}

export const pulseSignalFilterCondition = [
  'MindBio',
  'LSD',
  'Meaning Centred Psychotherapy',
]

export const pulseSignalAlarm = [
  {
    title: 'Media Mention Alert',
    percent: 75,
  },
  {
    title: 'Sentiment Analysis Alert',
    percent: 28,
  },
  {
    title: 'Media Surge Alert',
    percent: 93,
  },
]

export const pulseSignalfilter = {
  'Time Period': [
    '15 mins',
    '24 hrs',
    '1 hr',
    '7 days',
    '6 hrs',
    '30 days',
    '12 hrs',
    '365 days',
  ],
  Region: [
    'North America',
    'Asia',
    'Europe',
    'Africa',
    'South America',
    'Global',
    'Australia',
  ],
  'Media Type': [
    'Social Media',
    'Digital Media (Google)',
    'Digital Media (Firesight)',
    'All Media',
  ],
  'Measurement Type': [
    'Positive Sentiment',
    'Negative Sentiment',
    'Media Mentions',
    'Narrative Mentions',
  ],
}

export const signalMenuList = [
  'Summaries',
  'Sentiment Analysis',
  'Geographic Popularity',
  'Media Volume',
  'Share of Voice',
  'Narrative Mentions',
]

export const pulseSignalDashboard = {
  activeKeyword: {
    type: 1,
    activePolygonRing: true,
    PolygonRing: false,
    textList: [
      {
        text: "'Anybody but him': GOP senators go public with disdain for Trump OP senators go public with disdain",
      },
      {
        text: 'NY Times reporter reveals how Trump advisers were feeling...',
      },
      {
        text: 'Elon Musk finds new Twitter CEO',
      },
      {
        text: 'The COVID Public Health Emergency declaration has ended.',
      },
      {
        text: 'JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand...',
      },
    ],
  },
  activeNarrative: {
    type: 2,
    activePolygonRing: true,
    PolygonRing: false,
    textList: [
      {
        text: "'Anybody but him': GOP senators go public with disdain for Trump OP senators go public with disdain",
      },
      {
        text: 'NY Times reporter reveals how Trump advisers were feeling...',
      },
      {
        text: 'Elon Musk finds new Twitter CEO',
      },
      {
        text: 'The COVID Public Health Emergency declaration has ended.',
      },
      {
        text: 'JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand...',
      },
    ],
  },
  inActiveKeyword: {
    type: 3,
    activePolygonRing: false,
    PolygonRing: true,
    textList: [
      {
        text: "'Anybody but him': GOP senators go public with disdain for Trump OP senators go public with disdain",
      },
      {
        text: 'NY Times reporter reveals how Trump advisers were feeling...',
      },
      {
        text: 'Elon Musk finds new Twitter CEO',
      },
      {
        text: 'The COVID Public Health Emergency declaration has ended.',
      },
      {
        text: 'JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand...',
      },
    ],
  },
  inActiveNarrative: {
    type: 4,
    activePolygonRing: false,
    PolygonRing: true,
    textList: [
      {
        text: "'Anybody but him': GOP senators go public with disdain for Trump OP senators go public with disdain",
      },
      {
        text: 'NY Times reporter reveals how Trump advisers were feeling...',
      },
      {
        text: 'Elon Musk finds new Twitter CEO',
      },
      {
        text: 'The COVID Public Health Emergency declaration has ended.',
      },
      {
        text: 'JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand...',
      },
    ],
  },
  activeSmart: {
    type: 5,
    activePolygonRing: false,
    PolygonRing: true,
    textList: [
      {
        text: "'Anybody but him': GOP senators go public with disdain for Trump OP senators go public with disdain",
      },
      {
        text: 'NY Times reporter reveals how Trump advisers were feeling...',
      },
      {
        text: 'Elon Musk finds new Twitter CEO',
      },
      {
        text: 'The COVID Public Health Emergency declaration has ended.',
      },
      {
        text: 'JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand...',
      },
    ],
  },
  SurveillanceSuggestions: {
    type: 6,
    activePolygonRing: false,
    PolygonRing: true,
    textList: [
      {
        text: "'Anybody but him': GOP senators go public with disdain for Trump OP senators go public with disdain",
      },
      {
        text: 'NY Times reporter reveals how Trump advisers were feeling...',
      },
      {
        text: 'Elon Musk finds new Twitter CEO',
      },
      {
        text: 'The COVID Public Health Emergency declaration has ended.',
      },
      {
        text: 'JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand...',
      },
    ],
  },
}
