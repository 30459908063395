'use client' //this is a client side component

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  agentMode: 'single',
  speed: 3,
  intelligence: 2,
  creativity: 0,
}

export const msgSettingsSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {
    switchAgentMode: (state, action) => {
      state.agentMode = action.payload ? 'multi' : 'single'
    },
    setSpeed: (state, action) => {
      state.speed = action.payload
    },
    setIntelligence: (state, action) => {
      state.intelligence = action.payload
    },
    setCreativity: (state, action) => {
      state.creativity = action.payload
    },
  },
})

export const { switchAgentMode, setSpeed, setIntelligence, setCreativity } =
  msgSettingsSlice.actions

export default msgSettingsSlice.reducer
