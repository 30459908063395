'use client'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import msgSettingsSlice from './features/messageSettings/msgSettingsSlice'
import headerSlice from './features/header/headerSlice'
import chatSlice from './features/chat/chatSlice'

const rootReducer = combineReducers({
  msgSettings: msgSettingsSlice,
  header: headerSlice,
  chat: chatSlice,
  //add all your reducers here
})

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
})
