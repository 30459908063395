import React from "react";
import { Modal } from "antd";
import styled from "@emotion/styled";

const StyledModal = styled(Modal)`
  & .ant-modal-mask {
    backdrop-filter: blur(6.5px) !important;
  }
  & .ant-modal-content {
    padding: 27px;
  }
  & .ant-modal-body {
    color: white;
  }
  & .ant-modal-close-x {
    color: white;
  }
`;

export const SimpleModal = (props) => {
  return (
    <>
      <StyledModal
        style={{
          top: 180,
          color: "white",
        }}
        width={props.width ? props.width : ""}
        open={props.open}
        onOk={() => { props.setModal(false) }}
        onCancel={() => { props.setModal(false) }}
        footer={props.footer}
      >
        {props.modalCnt}
      </StyledModal>
    </>
  );
};
