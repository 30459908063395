export const authTestData = {
  name: 'Julia Stonebridge',
  title: 'firesight.ai',
  description: 'Disrupting the status quo since 1993.',
}

export const authData = {
  menuList: [
    'Modes',
    'Knowledge',
    'Following',
    'Personalisation Options',
    'Settings & Account Details',
    'Credit Usage',
  ],
  criminalMenuList: ['Topics', 'Hashtags', 'Publishers', 'Publisher Topics'],
  mode_public_menuList: [
    'Knowledge Base',
    'Knowledge Session',
    'Topics',
    'Hashtags',
    'Publishers',
    'Publisher Topics',
  ],
  knowledge_detail_menuList: [
    'File Tree',
    'File Type',
    'Modes',
    'Recent Activity',
  ],
  knowledge_device_menuList: [
    {
      device: 'Macbook Pro',
      syncStatus: true,
      startingTime: '1:45 PM',
      endingTime: '11.05.22',
    },
    {
      device: 'Macbook Pro',
      syncStatus: true,
      startingTime: '1:45 PM',
      endingTime: '11.05.22',
    },
    {
      device: 'Macbook Pro',
      syncStatus: true,
      startingTime: '1:45 PM',
      endingTime: '11.05.22',
    },
    {
      device: 'Macbook Pro',
      syncStatus: true,
      startingTime: '1:45 PM',
      endingTime: '11.05.22',
    },
    {
      device: 'Macbook Pro',
      syncStatus: true,
      startingTime: '1:45 PM',
      endingTime: '11.05.22',
    },
    {
      device: 'Macbook Pro',
      syncStatus: true,
      startingTime: '1:45 PM',
      endingTime: '11.05.22',
    },
    {
      device: 'Macbook Pro',
      syncStatus: true,
      startingTime: '1:45 PM',
      endingTime: '11.05.22',
    },
  ],
  knowledge_card_present: [
    {
      icon: 6,
      title: 'Device Data & Files',
      description: 'Info from device syncs',
      fileCount: 34,
      totalVolum: 20.5,
      whileVolumn: 50,
      percent: 74,
    },
    {
      icon: 7,
      title: 'Custom Data & Files',
      description: 'Info from manual uploads',
      fileCount: 153,
      totalVolum: 43.8,
      whileVolumn: 50,
      percent: 93,
    },
  ],
  knowledge_card: {
    fileTreeRelation: [
      {
        name: 'MindBio Therapeutics',
        file: 'Multiple',
        size: '12.3mb',
        modeList: ['Actuary', 'Actuary'],
        time: '1:45 PM - 11.05.22',
      },
      {
        name: 'Lysergic Acid Diethylamide',
        file: 'Media',
        size: '12.3mb',
        modeList: [],
        time: '05:18 AM - 11.05.21',
      },
      {
        name: 'Lysergic Acid Diethylamide',
        file: 'Media',
        size: '12.3mb',
        modeList: [],
        time: '23:27 AM - 16.54.01',
      },
      {
        name: 'MindBio Therapeutics',
        file: 'Multiple',
        size: '12.3mb',
        modeList: ['Actuary', 'Actuary'],
        time: '1:45 PM - 11.05.22',
      },
      {
        name: 'Lysergic Acid Diethylamide',
        file: 'Media',
        size: '12.3mb',
        modeList: [],
        time: '05:18 AM - 11.05.21',
      },
      {
        name: 'Lysergic Acid Diethylamide',
        file: 'Media',
        size: '12.3mb',
        modeList: [],
        time: '23:27 AM - 16.54.01',
      },
      {
        name: 'MindBio Therapeutics',
        file: 'Multiple',
        size: '12.3mb',
        modeList: ['Actuary', 'Actuary'],
        time: '1:45 PM - 11.05.22',
      },
      {
        name: 'Lysergic Acid Diethylamide',
        file: 'Media',
        size: '12.3mb',
        modeList: [],
        time: '05:18 AM - 11.05.21',
      },
      {
        name: 'Lysergic Acid Diethylamide',
        file: 'Media',
        size: '12.3mb',
        modeList: [],
        time: '23:27 AM - 16.54.01',
      },
      {
        name: 'MindBio Therapeutics',
        file: 'Multiple',
        size: '12.3mb',
        modeList: ['Actuary', 'Actuary'],
        time: '1:45 PM - 11.05.22',
      },
      {
        name: 'Lysergic Acid Diethylamide',
        file: 'Media',
        size: '12.3mb',
        modeList: [],
        time: '05:18 AM - 11.05.21',
      },
      {
        name: 'Lysergic Acid Diethylamide',
        file: 'Media',
        size: '12.3mb',
        modeList: [],
        time: '23:27 AM - 16.54.01',
      },
      {
        name: 'MindBio Therapeutics',
        file: 'Multiple',
        size: '12.3mb',
        modeList: ['Actuary', 'Actuary'],
        time: '1:45 PM - 11.05.22',
      },
      {
        name: 'Lysergic Acid Diethylamide',
        file: 'Media',
        size: '12.3mb',
        modeList: [],
        time: '05:18 AM - 11.05.21',
      },
      {
        name: 'Lysergic Acid Diethylamide',
        file: 'Media',
        size: '12.3mb',
        modeList: [],
        time: '23:27 AM - 16.54.01',
      },
    ],
    modesRelation: [
      {
        icon: 1,
        title: 'Actuary',
        fileCount: 34,
        totalVolum: 20.5,
        percent: 74,
      },
      {
        icon: 4,
        title: 'Actuary',
        fileCount: 34,
        totalVolum: 20.5,
        percent: 93,
      },
      {
        icon: 3,
        title: 'Actuary',
        fileCount: 34,
        totalVolum: 20.5,
        percent: 66,
      },
      {
        icon: 2,
        title: 'Actuary',
        fileCount: 34,
        totalVolum: 20.5,
        percent: 60,
      },
      {
        icon: 1,
        title: 'Actuary',
        fileCount: 34,
        totalVolum: 20.5,
        percent: 93,
      },
      {
        icon: 1,
        title: 'Actuary',
        fileCount: 34,
        totalVolum: 20.5,
        percent: 28,
      },
      {
        icon: 2,
        title: 'Actuary',
        fileCount: 34,
        totalVolum: 20.5,
        percent: 58,
      },
      {
        icon: 4,
        title: 'Actuary',
        fileCount: 34,
        totalVolum: 20.5,
        percent: 10,
      },
      {
        icon: 3,
        title: 'Actuary',
        fileCount: 34,
        totalVolum: 20.5,
        percent: 74,
      },
      {
        icon: 4,
        title: 'Actuary',
        fileCount: 34,
        totalVolum: 20.5,
        percent: 10,
      },
    ],
    fileRelation: [
      {
        icon: 5,
        title: 'Images',
        fileCount: 34,
        totalVolum: 20.5,
        percent: 74,
      },
      {
        icon: 5,
        title: 'Documents',
        fileCount: 84,
        totalVolum: 5.7,
        percent: 81,
      },
      {
        icon: 5,
        title: 'Url’s',
        fileCount: 28,
        totalVolum: 8.7,
        percent: 29,
        timeLine: true,
      },
      {
        icon: 5,
        title: 'API’s',
        fileCount: 136,
        totalVolum: 15.6,
        percent: 89,
      },
      {
        icon: 5,
        title: 'Audio Files',
        fileCount: 94,
        totalVolum: 8.7,
        percent: 14,
      },
      {
        icon: 5,
        title: 'Videos',
        fileCount: 32,
        totalVolum: 8.7,
        percent: 39,
      },
    ],
  },
  unlock_premiu: [
    {
      text: 'Actuary',
    },
    {
      text: 'Analyst',
    },
    {
      text: 'Astronomer',
    },
    {
      text: 'Business Intelligence',
    },
    {
      text: 'Criminologist',
    },
    {
      text: 'Data Scientist',
      icon: true,
    },
    {
      text: 'Astronomer',
    },
    {
      text: 'Business Intelligence',
    },
    {
      text: 'Criminologist',
    },
    {
      text: 'Data Scientist',
      icon: true,
    },
  ],
  criminalMode: [
    {
      category: 'Sport',
      title: 'Footerball',
      subTitle: 'Australian A-League',
      icon: 1,
    },
    {
      category: 'Geography',
      title: 'Mountains',
      subTitle: 'Rocky Mountains',
      icon: 2,
    },
    {
      category: 'World',
      title: 'Australia',
      subTitle: 'Sydney theatre',
      icon: 3,
    },
    {
      category: 'Design',
      title: 'UI/UX',
      subTitle: '2023 design trends',
      icon: 2,
    },
    {
      category: 'World',
      title: 'Australia',
      subTitle: 'Sydney theatre',
      icon: 4,
    },
    {
      category: 'Sport',
      title: 'Footerball',
      subTitle: 'Australian A-League',
      icon: 2,
    },
    {
      category: 'Design',
      title: 'UI/UX',
      subTitle: '2023 design trends',
      icon: 3,
    },
    {
      category: 'Geography',
      title: 'Mountains',
      subTitle: 'Rocky Mountains',
      icon: 1,
    },
    {
      category: 'World',
      title: 'Australia',
      subTitle: 'Sydney theatre',
      icon: 1,
    },
    {
      category: 'Geography',
      title: 'Mountains',
      subTitle: 'Rocky Mountains',
      icon: 3,
    },

    {
      category: 'World',
      title: 'Australia',
      subTitle: 'Sydney theatre',
      icon: 4,
    },
    {
      category: 'Sport',
      title: 'Footerball',
      subTitle: 'Australian A-League',
      icon: 3,
    },

    {
      category: 'Sport',
      title: 'Footerball',
      subTitle: 'Australian A-League',
      icon: 4,
    },
    {
      category: 'Design',
      title: 'UI/UX',
      subTitle: '2023 design trends',
      icon: 4,
    },

    {
      category: 'Geography',
      title: 'Mountains',
      subTitle: 'Rocky Mountains',
      icon: 2,
    },
    {
      category: 'Design',
      title: 'UI/UX',
      subTitle: '2023 design trends',
      icon: 3,
    },

    {
      category: 'Sport',
      title: 'Footerball',
      subTitle: 'Australian A-League',
      icon: 1,
    },
    {
      category: 'Geography',
      title: 'Mountains',
      subTitle: 'Rocky Mountains',
      icon: 1,
    },
    {
      category: 'World',
      title: 'Australia',
      subTitle: 'Sydney theatre',
      icon: 3,
    },
    {
      category: 'Design',
      title: 'UI/UX',
      subTitle: '2023 design trends',
      icon: 2,
    },
    {
      category: 'World',
      title: 'Australia',
      subTitle: 'Sydney theatre',
      icon: 3,
    },
  ],
  sessionDetail1: {
    documents: ['Document1.doc', 'Document2.doc', 'Document3.doc'],
    videos: ['Video1.mp4', 'Video2.mp4', 'Video3.mp4'],
    urls: ['Link1', 'Link2', 'Link3'],
    apis: ['API1', 'API2', 'API3'],
    images: ['Image1.png', 'Image2.png', 'Image3.png'],
    audios: ['Audio1.png', 'Audio2.png', 'Audio3.png'],
  },
  sessionDetail2: {
    documents: ['Document1.doc', 'Document2.doc'],
    urls: ['Link1', 'Link2', 'Link3'],
    apis: ['API1', 'API2', 'API3'],
    audios: ['Audio1.png', 'Audio2.png', 'Audio3.png'],
  },
}
export const settingAndProfileURL = {
  0: '/setting-and-profile/modes',
  1: '/setting-and-profile/knowledge',
  2: '/setting-and-profile/following',
  3: '/setting-and-profile/personalisation',
  4: '/setting-and-profile/settings-account',
  5: '/setting-and-profile/subscriptions',
}
