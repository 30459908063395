import DashboardIcon from '@/assets/img/icons/dashboard_white_24x24.svg'
import ChatIcon from '@/assets/img/icons/hive_white_24x24.svg'
import WorkIcon from '@/assets/img/icons/work_white_24x24.svg'
import NewsIcon from '@/assets/img/icons/news_white_24x24.svg'
import GraphIcon from '@/assets/img/icons/graph_white_24x24.svg'
import PulseIcon from '@/assets/img/icons/pulse_white_24x24.svg'

import LinkedInIcon from '@/assets/img/footer/footer_linkedin.svg'
import MediumIcon from '@/assets/img/footer/footer_medium.svg'
import InstagramIcon from '@/assets/img/footer/footer_instagram.svg'
import TwitterIcon from '@/assets/img/footer/footer_twitter.svg'
import DiscordIcon from '@/assets/img/footer/footer_discord.svg'

export const headerData = {
  iconList: [LinkedInIcon, MediumIcon, InstagramIcon, TwitterIcon, DiscordIcon],
  hambergerList: [
    {
      text: 'Product',
      icon: undefined,
      route: '/',
    },
    {
      text: 'Pricing',
      icon: undefined,
      route: '/static/pricing',
    },
    {
      text: 'Solutions',
      icon: undefined,
      route: '/static/solutions',
    },
    {
      text: 'About Us',
      icon: undefined,
      route: '/static/about',
    },
    {
      text: 'Desktop App',
      icon: undefined,
      route: '/static/desktop-app',
    },
    {
      text: 'AI Impact Index',
      icon: undefined,
      route: 'https://impact.firesight.ai/ai-impact-index/categories',
    },
    {
      text: 'Dashboard',
      icon: DashboardIcon,
      route: '/dashboard',
    },
    {
      text: 'Chat',
      icon: ChatIcon,
      route: '/chat/chats',
    },
    {
      text: 'Work',
      icon: WorkIcon,
      route: '/work/jobs',
    },
    {
      text: 'News',
      icon: NewsIcon,
      route: '/news/smart-on',
    },
    {
      text: 'Graph',
      icon: GraphIcon,
      route: '/graph/queries?graphId=latest',
    },
    {
      text: 'Pulse',
      icon: PulseIcon,
      route: '/pulse',
    },
  ],
  sessionList: [
    'UK Crime Rate Data',
    'European Felony Conviction Data',
    'My Personal Notes + Data',
    'World Crime Case Data',
    'World Crime Case Articles',
    'UK Crime Rate Data',
    'European Felony Conviction Data',
    'My Personal Notes + Data',
    'World Crime Case Data',
    'World Crime Case Articles',
  ],
  knowledgeSessionList: [
    'Text & Docs',
    'Videos',
    'Links',
    'Secrets',
    'Images',
    'Audio',
  ],
  dashboard: {
    title: 'Dashboard',
    left: {
      text: '',
      url: '',
    },
    right: {
      text: '',
      url: '',
    },
  },
  chat: {
    title: 'Chat',
    left: {
      text: 'chats',
      url: '/chat/chats',
    },
    right: {
      text: 'prompts',
      url: '/chat/prompts',
    },
  },
  work: {
    title: 'Work',
    left: {
      text: 'Jobs',
      url: '/work/jobs',
    },
    right: {
      text: 'Prompts',
      url: '/work/prompts',
    },
  },
  news: {
    title: 'News',
    left: {
      text: 'Smart Mode',
      url: '/news/smart-off',
    },
    right: {
      text: '',
      url: '/news/smart-on',
    },
  },
  graph: {
    title: 'Graph',
    left: {
      text: 'Graphs',
      url: '/graph/queries',
    },
    right: {
      text: 'Archive',
      url: '/graph/archive',
    },
  },
  pulse: {
    title: 'Pulse',
    left: {
      text: '',
      url: '',
    },
    right: {
      text: '',
      url: '',
    },
  },
  notifications: {
    title: 'Notifications',
    left: {
      text: '',
      url: '',
    },
    right: {
      text: '',
      url: '',
    },
  },
}
