export const hiveData = {
  chats: {
    menuList: [
      'Chats',
      // 'Chat Media',
      'Archive',
      // 'Smart Chat Alarms',
      'Trending',
      'Recommended',
      'Tasks',
      'Goals',
      'Jobs',
    ],
    notification: {
      notificationType: {
        title: 'NOTIFICATION TYPE',
        list: ['Email Notification', 'Push Notification'],
      },
      notificationFrequency: {
        title: 'NOTIFICATION FREQUENCY',
        list: [
          '15 mins',
          '24 hrs',
          '1 hr',
          '7 days',
          '6 hrs',
          '14 days',
          '12 hrs',
          '30 days',
        ],
      },
      aiNotification: {
        title: 'AI Controlled notifications ',
        list: ['When Firesight feels it necessary to update you'],
      },
    },
    archieve: {
      testStaticList: ['Last Hour', 'Last 12 Hours', 'Last 24 Hours'],
    },
  },
  categoryData: [
    'Default',
    'Actuary',
    'Analyst',
    'Criminologist',
    'Politics',
    'Business Intelligence',
    'Astronomer',
    'Data Scientist',
    'Financial Analyst',
    'Psychologist',
    'Sociologist',
  ],
  notification: {
    sortNotificationBy: {
      title: 'SORT NOTIFICATION BY',
      list: ['TYPE', 'TIME'],
    },
    notificationStatus: {
      title: 'NOTIFICATION STATUS',
      list: ['Current', 'Archive'],
    },
    notificationCategory: {
      title: 'NOTIFICATION CATEGORY',
      list: [
        'All Categories',
        'Dashboard',
        'Hive',
        'Swarm',
        'News',
        'Graph',
        'Pulse',
        'Notifications',
      ],
    },
  },
  carouselData: [
    {
      text: ['Default', 'Actuary', 'Analyst', 'Criminologist', 'politics'],
    },
    {
      text: ['Recommand', 'Analyst', 'Actuary', 'Default'],
    },
    {
      text: ['Analyst', 'Recommand', 'Default', 'Actuary'],
    },
    {
      text: ['Default', 'Analyst', 'Recommand', 'Actuary'],
    },
  ],
  panelData: {
    menuList: ['Trending', 'Recommended', 'Tasks', 'Goals', 'Jobs'],
    trending: [
      "'Anybody but him': GOP senators go public with disdain for Trump",
      'NY Times reporter reveals how Trump advisers were feeling...',
      'Elon Musk finds new Twitter CEO',
      'The COVID Public Health Emergency declaration has ended.',
      'JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand...',
      'NY Times reporter reveals how Trump advisers were feeling...',
      'Elon Musk finds new Twitter CEO',
      'The COVID Public Health Emergency declaration has ended.',
      'JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand...',
      'Elon Musk finds new Twitter CEO',
      'The COVID Public Health Emergency declaration has ended.',
      'JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand...',
      'NY Times reporter reveals how Trump advisers were feeling...',
      'Elon Musk finds new Twitter CEO',
      'The COVID Public Health Emergency declaration has ended.',
      'JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand...',
      'Elon Musk finds new Twitter CEO',
      'The COVID Public Health Emergency declaration has ended.',
      'JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand...',
      'NY Times reporter reveals how Trump advisers were feeling...',
      'Elon Musk finds new Twitter CEO',
      'The COVID Public Health Emergency declaration has ended.',
      'JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand...',
      'The COVID Public Health Emergency declaration has ended.',
      'JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand...',
      'Elon Musk finds new Twitter CEO',
      'The COVID Public Health Emergency declaration has ended.',
      'JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand...',
      'NY Times reporter reveals how Trump advisers were feeling...',
      'Elon Musk finds new Twitter CEO',
      'The COVID Public Health Emergency declaration has ended.',
      'JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand...',
    ],
  },
  SelfPanelData: {
    chatArchieveMenuList: ['Today', '7 Days', '30 Days'],
    chatsArchieveList: [
      'Group heckles comedian, storms out after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'Mick Hucknall calls Rishi Sunak a disgusting tout after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump f',
      'Megret over protestor arrests at Kings Group heckles comedian, storms  advisers were fet Police expresses r',
      'Charles made on Buckingham Palace balcony GroupThe 7-word moan King  heckles comedian, storms out after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'CNN labels Tucker Carlson Right-wing extremist Group heckles comedian, storms out after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'Met Police expresses regret over protestor arrests at Kings Group heckles comedian, storms  advisers were f',
      'Buckingham Palace balcony Group heckles comedian, storms out after Dylan MuThe 7-word moan King Charles made on lvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'CNN labels Tucker Carlson Right-wing extremist Group heckles comedian, storms out after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'James Gunn confirms fan theory is true about Guardians of the Galaxy..Group heckles comedian, storms out after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'Boris Johnson called Sue Gray psycho, says ex-No 10 spin doctorGroup heckles comedian, storms out after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'Group heckles comedian, storms out after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'Mick Hucknall calls Rishi Sunak a disgusting tout after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump f',
      'Megret over protestor arrests at Kings Group heckles comedian, storms  advisers were fet Police expresses r',
      'Charles made on Buckingham Palace balcony GroupThe 7-word moan King  heckles comedian, storms out after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'CNN labels Tucker Carlson Right-wing extremist Group heckles comedian, storms out after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'Met Police expresses regret over protestor arrests at Kings Group heckles comedian, storms  advisers were f',
      'Buckingham Palace balcony Group heckles comedian, storms out after Dylan MuThe 7-word moan King Charles made on lvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'CNN labels Tucker Carlson Right-wing extremist Group heckles comedian, storms out after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'James Gunn confirms fan theory is true about Guardians of the Galaxy..Group heckles comedian, storms out after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'Boris Johnson called Sue Gray psycho, says ex-No 10 spin doctorGroup heckles comedian, storms out after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'Mick Hucknall calls Rishi Sunak a disgusting tout after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump f',
      'Megret over protestor arrests at Kings Group heckles comedian, storms  advisers were fet Police expresses r',
      'Charles made on Buckingham Palace balcony GroupThe 7-word moan King  heckles comedian, storms out after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'CNN labels Tucker Carlson Right-wing extremist Group heckles comedian, storms out after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'Met Police expresses regret over protestor arrests at Kings Group heckles comedian, storms  advisers were f',
      'Buckingham Palace balcony Group heckles comedian, storms out after Dylan MuThe 7-word moan King Charles made on lvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'CNN labels Tucker Carlson Right-wing extremist Group heckles comedian, storms out after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'James Gunn confirms fan theory is true about Guardians of the Galaxy..Group heckles comedian, storms out after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'Boris Johnson called Sue Gray psycho, says ex-No 10 spin doctorGroup heckles comedian, storms out after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'Mick Hucknall calls Rishi Sunak a disgusting tout after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump f',
      'Megret over protestor arrests at Kings Group heckles comedian, storms  advisers were fet Police expresses r',
      'Charles made on Buckingham Palace balcony GroupThe 7-word moan King  heckles comedian, storms out after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'CNN labels Tucker Carlson Right-wing extremist Group heckles comedian, storms out after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'Met Police expresses regret over protestor arrests at Kings Group heckles comedian, storms  advisers were f',
      'Buckingham Palace balcony Group heckles comedian, storms out after Dylan MuThe 7-word moan King Charles made on lvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'CNN labels Tucker Carlson Right-wing extremist Group heckles comedian, storms out after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'James Gunn confirms fan theory is true about Guardians of the Galaxy..Group heckles comedian, storms out after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
      'Boris Johnson called Sue Gray psycho, says ex-No 10 spin doctorGroup heckles comedian, storms out after Dylan Mulvaney jokedvisers were feeling reporter reveals how Trump advisers were f',
    ],
  },
  SmartChatConverMainData: [
    {
      topicId: 0,
      userId: 0,
      text: 'Find open position for Data Engineer roles, across social media, search, and anywhere else',
    },
    {
      topicId: 0,
      userId: null,
      textList: [
        'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred dBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living withdBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred anxiety and or depression.',
        'Centred PsychotherapyMindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid DiethydBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred lamide (LSD) and Meaning  for.',
      ],
    },
    {
      topicId: 1,
      userId: 0,
      text: 'MindBio secures ethics approval for advanced stage cancer therapy trial',
    },
    {
      topicId: 1,
      userId: null,
      textList: [
        'Intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 1,
      userId: 0,
      text: 'MindBio secures ethics approval for advanced stage cancer therapy trial',
    },
    {
      topicId: 1,
      userId: null,
      textList: [
        'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 1,
      userId: 0,
      text: 'COVID Public Health Emergency, send me daily reports please.',
    },
    {
      topicId: 1,
      userId: null,
      textList: [
        'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 1,
      userId: 0,
      text: 'MindBio secures ethics approval for advanced stage cancer therapy trial',
    },
    {
      topicId: 1,
      userId: null,
      textList: [
        'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 2,
      userId: 0,
      text: 'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
    },
    {
      topicId: 2,
      userId: null,
      textList: [
        'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 2,
      userId: 0,
      text: 'MindBio secures ethics approval for advanced stage cancer therapy trial',
    },
    {
      topicId: 2,
      userId: null,
      textList: [
        'peutics has received ethics approval for a Phase II trial of microdoses oMindBio Theraf Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
        'Meanotherapy is a psychotherapeutic intervention used to treat advanced cancer patients ling Centred Psychiving with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 2,
      userId: 0,
      text: 'COVID Public Health Emergency, send me daily reports please.',
    },
    {
      topicId: 2,
      userId: null,
      textList: [
        'gic Acid Diethylamide (LSD) and Meaning Cenpeutics has received ethics approval for a Phase II trial of microdoses oMindBio Theraf Lysertred Psychotherapy for.',
        'tients ling Centred Psychiving with symptoms of anxiety and or depresMeanotherapy is a psychotherapeutic intervention used to treat advanced cancer pasion.',
      ],
    },
    {
      topicId: 2,
      userId: 0,
      text: 'MindBio secures ethics approval for advanced stage cancer therapy trial',
    },
    {
      topicId: 2,
      userId: null,
      textList: [
        'gic Acid Diethylamide (LSD) and Meaning Cenpeutics has received ethics approval for a Phase II trial of microdoses oMindBio Theraf Lysertred Psychotherapy for.',
      ],
    },
    {
      topicId: 3,
      userId: 0,
      text: 'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
    },
    {
      topicId: 3,
      userId: null,
      textList: [
        'tred Psychiving with symptoms of anxiety and or gic Acid Diethylamimicrodoses oMindBio Theraf Lysertred Psychotherapy for.',
        'de (LSD) and Meaning Cenpeutics has received ethics approval for a Phase II trial of tients ling CendepresMeanotherapy is a psychotherapeutic intervention used to treat advanced cancer pasion.',
      ],
    },
    {
      topicId: 3,
      userId: 0,
      text: 'MindBio secures ethics approval for advanced stage cancer therapy trial',
    },
    {
      topicId: 3,
      userId: null,
      textList: [
        'ms of anxiety and or gic Acid Diethylamimicrodoses oMindBitred Psychiving with symptoo Theraf Lysertred Psychotherapy for.',
        'tics has received ethics approde (LSD) and Meaning Cenpeuval for a Phase II trial of tients ling CendepresMeanotherapy is a psychotherapeutic intervention used to treat advanced cancer pasion.',
      ],
    },
    {
      topicId: 3,
      userId: 0,
      text: 'COVID Public Health Emergency, send me daily reports please.',
    },
    {
      topicId: 3,
      userId: null,
      textList: [
        'ms of anxiety and or gic Acid Diethylamimicrodoses oMindBitred Psychiving with symptoo Theraf Lysertred Psychotherapy for.',
        'tics has received ethics approde (LSD) and Meaning Cenpeuval for a Phase II trial of tients ling CendepresMeanotherapy is a psychotherapeutic intervention used to treat advanced cancer pasion.',
      ],
    },
    {
      topicId: 4,
      userId: 0,
      text: 'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
    },
    {
      topicId: 4,
      userId: 0,
      text: 'MindBio secures ethics approval for advanced stage cancer therapy trial',
    },
    {
      topicId: 4,
      userId: null,
      textList: [
        'ms of anxiety and or gic Acid Diethylamimicrodoses oMindBitred Psychiving with symptoo Theraf Lysertred Psychotherapy for.',
        'tics has received ethics approde (LSD) and Meaning Cenpeuval for a Phase II trial of tients ling CendepresMeanotherapy is a psychotherapeutic intervention used to treat advanced cancer pasion.',
        'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 4,
      userId: 0,
      text: 'MindBio secures ethics approval for advanced stage cancer therapy trial',
    },
    {
      topicId: 4,
      userId: null,
      textList: [
        'ms of anxiety and or gic Acid Diethylamimicrodoses oMindBitred Psychiving with symptoo Theraf Lysertred Psychotherapy for.',
        'tics has received ethics approde (LSD) and Meaning Cenpeuval for a Phase II trial of tients ling CendepresMeanotherapy is a psychotherapeutic intervention used to treat advanced cancer pasion.',
        'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 5,
      userId: 0,
      text: 'COVID Public Health Emergency, send me daily reports please.',
    },
    {
      topicId: 5,
      textList: [
        'ms of anxiety and or gic Acid Diethylamimicrodoses oMindBitred Psychiving with symptoo Theraf Lysertred Psychotherapy for.',
        'tics has received ethics approde (LSD) and Meaning Cenpeuval for a Phase II trial of tients ling CendepresMeanotherapy is a psychotherapeutic intervention used to treat advanced cancer pasion.',
        'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 6,
      userId: 0,
      text: 'COVID Public Health Emergency, send me daily reports please.',
    },
    {
      topicId: 6,
      textList: [
        'ms of anxiety and or gic Acid Diethylamimicrodoses oMindBitred Psychiving with symptoo Theraf Lysertred Psychotherapy for.',
        'tics has received ethics approde (LSD) and Meaning Cenpeuval for a Phase II trial of tients ling CendepresMeanotherapy is a psychotherapeutic intervention used to treat advanced cancer pasion.',
        'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 7,
      userId: 0,
      text: 'MindBio secures ethics approval for advanced stage cancer therapy trial',
    },
    {
      topicId: 7,
      textList: [
        'ms of anxiety and or gic Acid Diethylamimicrodoses oMindBitred Psychiving with symptoo Theraf Lysertred Psychotherapy for.',
        'tics has received ethics approde (LSD) and Meaning Cenpeuval for a Phase II trial of tients ling CendepresMeanotherapy is a psychotherapeutic intervention used to treat advanced cancer pasion.',
        'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 8,
      userId: 0,
      text: 'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
    },
    {
      topicId: 8,
      userId: null,
      textList: [
        'ms of anxiety and or gic Acid Diethylamimicrodoses oMindBitred Psychiving with symptoo Theraf Lysertred Psychotherapy for.',
        'tics has received ethics approde (LSD) and Meaning Cenpeuval for a Phase II trial of tients ling CendepresMeanotherapy is a psychotherapeutic intervention used to treat advanced cancer pasion.',
        'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 9,
      userId: 0,
      text: 'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
    },
    {
      topicId: 9,
      textList: [
        'ms of anxiety and or gic Acid Diethylamimicrodoses oMindBitred Psychiving with symptoo Theraf Lysertred Psychotherapy for.',
        'tics has received ethics approde (LSD) and Meaning Cenpeuval for a Phase II trial of tients ling CendepresMeanotherapy is a psychotherapeutic intervention used to treat advanced cancer pasion.',
        'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 10,
      userId: 0,
      text: 'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
    },
    {
      topicId: 10,
      userId: null,
      textList: [
        'ms of anxiety and or gic Acid Diethylamimicrodoses oMindBitred Psychiving with symptoo Theraf Lysertred Psychotherapy for.',
        'tics has received ethics approde (LSD) and Meaning Cenpeuval for a Phase II trial of tients ling CendepresMeanotherapy is a psychotherapeutic intervention used to treat advanced cancer pasion.',
        'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 11,
      userId: 0,
      text: 'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
    },
    {
      topicId: 12,
      userId: 0,
      text: 'MindBio secures ethics approval for advanced stage cancer therapy trial',
    },
    {
      topicId: 12,
      userId: null,
      textList: [
        'ms of anxiety and or gic Acid Diethylamimicrodoses oMindBitred Psychiving with symptoo Theraf Lysertred Psychotherapy for.',
        'tics has received ethics approde (LSD) and Meaning Cenpeuval for a Phase II trial of tients ling CendepresMeanotherapy is a psychotherapeutic intervention used to treat advanced cancer pasion.',
        'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 13,
      userId: 0,
      text: 'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
    },
    {
      topicId: 13,
      userId: null,
      textList: [
        'ms of anxiety and or gic Acid Diethylamimicrodoses oMindBitred Psychiving with symptoo Theraf Lysertred Psychotherapy for.',
        'tics has received ethics approde (LSD) and Meaning Cenpeuval for a Phase II trial of tients ling CendepresMeanotherapy is a psychotherapeutic intervention used to treat advanced cancer pasion.',
        'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 14,
      userId: 0,
      text: 'COVID Public Health Emergency, send me daily reports please.',
    },
    {
      topicId: 14,
      userId: null,
      textList: [
        'ms of anxiety and or gic Acid Diethylamimicrodoses oMindBitred Psychiving with symptoo Theraf Lysertred Psychotherapy for.',
        'tics has received ethics approde (LSD) and Meaning Cenpeuval for a Phase II trial of tients ling CendepresMeanotherapy is a psychotherapeutic intervention used to treat advanced cancer pasion.',
        'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 14,
      userId: 0,
      text: 'MindBio secures ethics approval for advanced stage cancer therapy trial',
    },
    {
      topicId: 14,
      userId: null,
      textList: [
        'ms of anxiety and or gic Acid Diethylamimicrodoses oMindBitred Psychiving with symptoo Theraf Lysertred Psychotherapy for.',
        'tics has received ethics approde (LSD) and Meaning Cenpeuval for a Phase II trial of tients ling CendepresMeanotherapy is a psychotherapeutic intervention used to treat advanced cancer pasion.',
        'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 14,
      userId: 0,
      text: 'MindBio secures ethics approval for advanced stage cancer therapy trial',
    },
    {
      topicId: 15,
      userId: 0,
      text: 'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
    },
    {
      topicId: 15,
      userId: null,
      textList: [
        'ms of anxiety and or gic Acid Diethylamimicrodoses oMindBitred Psychiving with symptoo Theraf Lysertred Psychotherapy for.',
        'tics has received ethics approde (LSD) and Meaning Cenpeuval for a Phase II trial of tients ling CendepresMeanotherapy is a psychotherapeutic intervention used to treat advanced cancer pasion.',
        'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 15,
      userId: 0,
      text: 'COVID Public Health Emergency, send me daily reports please.',
    },
    {
      topicId: 15,
      userId: null,
      textList: [
        'ms of anxiety and or gic Acid Diethylamimicrodoses oMindBitred Psychiving with symptoo Theraf Lysertred Psychotherapy for.',
        'tics has received ethics approde (LSD) and Meaning Cenpeuval for a Phase II trial of tients ling CendepresMeanotherapy is a psychotherapeutic intervention used to treat advanced cancer pasion.',
        'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 16,
      userId: 0,
      text: 'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
    },
    {
      topicId: 16,
      userId: null,
      textList: [
        'ms of anxiety and or gic Acid Diethylamimicrodoses oMindBitred Psychiving with symptoo Theraf Lysertred Psychotherapy for.',
        'tics has received ethics approde (LSD) and Meaning Cenpeuval for a Phase II trial of tients ling CendepresMeanotherapy is a psychotherapeutic intervention used to treat advanced cancer pasion.',
        'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 16,
      userId: 0,
      text: 'MindBio secures ethics approval for advanced stage cancer therapy trial',
    },
    {
      topicId: 16,
      userId: null,
      textList: [
        'ms of anxiety and or gic Acid Diethylamimicrodoses oMindBitred Psychiving with symptoo Theraf Lysertred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 17,
      userId: 0,
      text: 'COVID Public Health Emergency, send me daily reports please.',
    },
    {
      topicId: 17,
      textList: [
        'ms of anxiety and or gic Acid Diethylamimicrodoses oMindBitred Psychiving with symptoo Theraf Lysertred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 17,
      userId: 0,
      text: 'MindBio secures ethics approval for advanced stage cancer therapy trial',
    },
    {
      topicId: 17,
      userId: null,
      textList: [
        'ms of anxiety and or gic Acid Diethylamimicrodoses oMindBitred Psychiving with symptoo Theraf Lysertred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 17,
      userId: 0,
      text: 'MindBio secures ethics approval for advanced stage cancer therapy trial',
    },
    {
      topicId: 17,
      userId: null,
      textList: [
        'ms of anxiety and or gic Acid Diethylamimicrodoses oMindBitred Psychiving with symptoo Theraf Lysertred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 18,
      userId: 0,
      text: 'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
    },
    {
      topicId: 18,
      userId: 0,
      text: 'COVID Public Health Emergency, send me daily reports please.',
    },
    {
      topicId: 18,
      userId: null,
      textList: [
        'ms of anxiety and or gic Acid Diethylamimicrodoses oMindBitred Psychiving with symptoo Theraf Lysertred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 18,
      userId: 0,
      text: 'MindBio secures ethics approval for advanced stage cancer therapy trial',
    },
    {
      topicId: 18,
      userId: null,
      textList: [
        'ms of anxiety and or gic Acid Diethylamimicrodoses oMindBitred Psychiving with symptoo Theraf Lysertred Psychotherapy for.',
        'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      ],
    },
    {
      topicId: 18,
      userId: 0,
      text: 'MindBio secures ethics approval for advanced stage cancer therapy trial',
    },
    {
      topicId: 18,
      userId: null,
      text: 'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for.',
    },
    {
      topicId: 18,
      userId: null,
      text: 'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
    },
  ],
}
