import lisaAvatar from '@/assets/img/avatar/lisa_24x24.svg'

export const notificationsData = {
  filterItem: {
    sortBy: ['Type', 'Time'],
    status: ['Current', 'Achieve'],
    category: [
      'All Categories',
      'Dashboard',
      'Chat',
      'Work',
      'News',
      'Graph',
      'Pulse',
      'Notifications',
    ],
  },
  dataArray: [
    {
      time: '16:22 01/06/2023',
      category: 'Work',
      text: 'Find open position for Data Engineer roles, across social media, search, and anywhere else',
      executedJob: 'Executed Job Task',
      evaluatingJob: 'Evaluating Job Succes',
    },
    {
      time: '16:22 01/06/2023',
      category: 'News',
      text: 'Find open position for Data Engineer roles, across social media, search, and anywhere else',
      publisher: 'Liza Strong',
      publisherAvatar: lisaAvatar,
    },
    {
      time: '16:22 01/06/2023',
      category: 'Work',
      text: 'Find open position for Data Engineer roles, across social media, search, and anywhere else',
      executedJob: 'Executed Job Task',
      evaluatingJob: 'Evaluating Job Succes',
    },
    {
      time: '16:22 01/06/2023',
      category: 'News',
      text: 'Find open position for Data Engineer roles, across social media, search, and anywhere else',
      publisher: 'Liza Strong',
      publisherAvatar: lisaAvatar,
    },
    {
      time: '16:22 01/06/2023',
      category: 'Work',
      text: 'Find open position for Data Engineer roles, across social media, search, and anywhere else',
      executedJob: 'Executed Job Task',
      evaluatingJob: 'Evaluating Job Succes',
    },
    {
      time: '16:22 01/06/2023',
      category: 'News',
      text: 'Find open position for Data Engineer roles, across social media, search, and anywhere else',
      publisher: 'Liza Strong',
      publisherAvatar: lisaAvatar,
    },
  ],
}
