'use client'
import { useState } from 'react'
import Image from 'next/image'
import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useSupabaseClient } from '@supabase/auth-helpers-react'

import styles from './HambergerModal.module.css'
import close from '@/assets/img/icons/close.png'

import { headerData } from '@/utils/refUtils'

import PolygonRedBg from '@/assets/img/icons/tablet_controller_polygon_red_40x40.svg'
import CognisantButton from '../button/CognisantButton'
import { CyanBtn_88x36 } from '@/utils/svg'

export function HambergerModal({ hambergerModalStatus, reverseStatus }) {
  const supabase = useSupabaseClient()
  const [piece, setPiece] = useState(null)
  const router = useRouter()
  const logout = async () => {
    await supabase.auth.signOut()
    router.push('/')
  }

  const currentURL = router.asPath

  const IconMenus = headerData.hambergerList
    .filter(item => item.icon === undefined)
    .map((item, index) => (
      <div key={index} className="hover:text-[#E93249] flex flex-col items-end">
        <div
          className="flex justify-end items-center py-4 lg:py-2 cursor-pointer md:h-[50px] lg:h-[40px] md:w-[150px] lg:w-full"
          onClick={() => setPiece(index)}
        >
          <Link href={item.route}>
            <p
              className={clsx(
                'hover:text-[#E93249] text-right',
                item.icon === undefined || piece === index
                  ? styles.hamberger_body_item_p_active
                  : styles.hamberger_body_item_p
              )}
              onClick={item.text === 'Log out' ? () => logout() : null}
            >
              {item.text}
            </p>
          </Link>
        </div>
        <hr className="w-[186px] opacity-10" />
      </div>
    ))

  const NonIconMenus = headerData.hambergerList
    .filter(item => item.icon !== undefined)
    .map((item, index) => (
      <div key={index} className="flex justify-end">
        <div
          className={styles.hamberger_body_item}
          onClick={() => router.push(item.route)}
        >
          <p
            className={clsx(
              'hover:text-[#E93249]',
              item.icon === undefined || currentURL === item.route
                ? styles.hamberger_body_item_p_active
                : styles.hamberger_body_item_p
            )}
          >
            {item.text}
          </p>

          <div className="ml-[10px] relative cursor-pointer">
            <Image
              src={PolygonRedBg}
              alt="red polygon bg"
              className={clsx(
                currentURL === item.route ? 'block' : 'hidden',
                'w-8 lg:w-10 h-8 lg:h-10'
              )}
            />
            <Image
              src={item.icon}
              alt="category icon"
              className={clsx(
                currentURL === item.route
                  ? 'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
                  : 'opacity-50',
                'w-[18px] lg:w-6 h-[18px] lg:h-6'
              )}
            />
          </div>
        </div>
      </div>
    ))

  return (
    <div
      className={clsx(
        hambergerModalStatus ? styles.hamberger_body_out : 'hidden'
      )}
    >
      <div className={styles.hamberger_body_in}>
        <Image
          src={close}
          alt="close"
          className="ml-auto opacity-50 cursor-pointer mb-4"
          onClick={() => reverseStatus(!hambergerModalStatus)}
        />
        <div className={styles.menuLayout}>
          <div className='md:w-[50%] lg:w-full'>{IconMenus}</div>
          <div>{NonIconMenus}</div>
        </div>
        <div className='hidden md:flex lg:hidden w-full flex-row justify-around items-end my-[50px]'>
          <div className='w-[50px] border-b-[1px] border-gray-700'></div>
          <p className=' text-[25px] font-bold leading-normal'>WORK LESS <b className=' text-red-600'>ACHIEVE</b> MORE</p>
          <div className='w-[50px] border-b-[1px] border-gray-700'></div>
        </div>
        <div className="flex justify-center items-center gap-4 mt-8">
          {headerData.iconList.map((item, index) => (
            <Image
              key={index}
              src={item}
              alt="item"
              className="cursor-pointer"
            />
          ))}
        </div>
        <div className="flex justify-end mt-5">
          <CognisantButton
            bgImage={CyanBtn_88x36}
            bgClass="w-[100px] lg:w-[150px] h-[33px] lg:h-9"
            onClick={() => logout()}
          >
            <span className="text-sm/normal lg:text-base/normal font-bold block lg:block">
              Log Out
            </span>
          </CognisantButton>
        </div>
      </div>
    </div>
  )
}
