export const graphArchive = {
  Favorites: [
    "'Anybody but him': GOP senators go public with disdain for Trump",
    'NY Times reporter reveals how Trump advisers were feeling...',
    'Elon Musk finds new Twitter CEO',
    'JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand...',
    "'Anybody but him': GOP senators go public with disdain for Trump",
    'NY Times reporter reveals how Trump advisers were feeling...',
    'Elon Musk finds new Twitter CEO',
    'JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand...',
  ],
  Today: [
    'Group heckles comedian, storms out after',
    'Mick Hucknall calls Rishi Sunak a',
    "Met Police expresses 'regret' over ",
    'The 7-word moan King Charles made, The 7-word moan King Charles made  ',
    "CNN labels Tucker Carlson 'Right . CNN labels Tucker Carlson 'Right CNN labels Tucker Carlson 'Right CNN labels Tucker Carlson 'Right ",
    'James Gunn confirms fan theory is ',
    'Boris Johnson called Sue Gray ',
    'Group heckles comedian, storms out after',
    'Mick Hucknall calls Rishi Sunak a',
    "Met Police expresses 'regret' over ",
    'The 7-word moan King Charles made, The 7-word moan King Charles made  ',
    "CNN labels Tucker Carlson 'Right . CNN labels Tucker Carlson 'Right CNN labels Tucker Carlson 'Right CNN labels Tucker Carlson 'Right ",
    'James Gunn confirms fan theory is ',
    'Boris Johnson called Sue Gray ',
  ],
  'Last 7 Days': [
    'Group heckles comedian, storms out after ',
    'Mick Hucknall calls Rishi Sunak a ',
    "Met Police expresses 'regret' over ",
    'Group heckles comedian, storms out after ',
    'Mick Hucknall calls Rishi Sunak a ',
    "Met Police expresses 'regret' over ",
  ],
}
