import { useDispatch } from 'react-redux'
import { useSupabaseClient } from '@supabase/auth-helpers-react'
import { setUserData, setUserRoles } from '@/redux/features/header/headerSlice'

export default function useUser() {
  const supabase = useSupabaseClient()
  const dispatch = useDispatch()

  const updateAccountDetails = async (userId, val) => {
    try {
      const { data, err } = await supabase
        .from('user_data')
        .update(val)
        .eq('user_id', userId)
        .select()
        .maybeSingle()
      dispatch(setUserData(data))
      if (err) {
        console.log(err)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const deleteUser = async userId => {
    try {
      const { error } = await supabase
        .from('user_data')
        .delete()
        .eq('user_id', userId)
      if (error) {
        console.error(error)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const getRolesOfUser = async () => {
    // Fetch all the user's roles
    const { data: userRoles, error: rolesError } = await supabase
      .rpc('get_user_properties')

    console.log("All roles of user:", userRoles)
    if (rolesError) {
      console.error(rolesError)
      return []
    }

    dispatch(setUserRoles(userRoles.map(role => role.property)))
    return userRoles
  }

  const userRoleAny = async (roles_to_check_for) => {
    // Check if user has any of the supplied roles
    try {
      const { data, error } = await supabase.rpc('user_property_in', {
        _properties: roles_to_check_for
      })

      if (error) {
        console.error(error)
        return false
      }

      return data
    } catch (err) {
      console.error(err)
      return false
    }
  }

  return { updateAccountDetails, deleteUser, getRolesOfUser, userRoleAny }
}
