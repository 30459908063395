import DashboardIcon from '@/assets/img/icons/dashboard_white_24x24.svg'
import ChatIcon from '@/assets/img/icons/hive_white_24x24.svg'
import WorkIcon from '@/assets/img/icons/work_white_24x24.svg'
import NewsIcon from '@/assets/img/icons/news_white_24x24.svg'
import GraphIcon from '@/assets/img/icons/graph_white_24x24.svg'
import PulseIcon from '@/assets/img/icons/pulse_white_24x24.svg'

export const modesList = [
  {
    type: 2,
    text: 'Default',
  },
  {
    type: 1,
    text: 'Actuary',
  },
  {
    type: 1,
    text: 'Analyst',
  },
  {
    type: 1,
    text: 'Astroment',
  },
  {
    type: 0,
    text: 'Actuary',
  },
  {
    type: 0,
    text: 'Analyst',
  },
  {
    type: 0,
    text: 'Astronomer',
  },
  {
    type: 0,
    text: 'Business Intelligence',
  },
  {
    type: 0,
    text: 'Criminologist',
  },
  {
    type: 0,
    text: 'Data Scientist',
  },
  {
    type: 0,
    text: 'Financial Analyst',
  },
  {
    type: 0,
    text: 'Psychologist',
  },
  {
    type: 0,
    text: 'Sociologist',
  },
]

export const topicsList = [
  {
    type: 2,
    text: 'Sport',
  },
  {
    type: 1,
    text: 'Tech',
  },
  {
    type: 1,
    text: 'Science',
  },
  {
    type: 3,
    text: 'Anthropology',
  },
  {
    type: 3,
    text: 'Archaeology',
  },
  {
    type: 4,
    text: 'Astronomer',
  },
  {
    type: 3,
    text: 'Biology',
  },
  {
    type: 4,
    text: 'Astronomy',
  },
  {
    type: 4,
    text: 'Biology',
  },
  {
    type: 3,
    text: 'Chemistry',
  },
  {
    type: 3,
    text: 'Climate Crisis',
  },
  {
    type: 5,
    text: 'Ecology',
  },
  {
    type: 5,
    text: 'Geology',
  },
  {
    type: 3,
    text: 'Journals',
  },
  {
    type: 3,
    text: 'Nanotechnology',
  },
  {
    type: 5,
    text: 'Neuroscience',
  },
]

export const sideData = [
  {
    icon: DashboardIcon,
    text: 'Dashboard',
    url: '/dashboard',
    menu: ['Modes', 'Topics'],
  },
  {
    icon: ChatIcon,
    text: 'Chat',
    url: '/chat/chats',
    menu: ['Modes', 'Topics'],
  },
  {
    icon: WorkIcon,
    text: 'Work',
    url: '/work/jobs',
    menu: ['Modes', 'Topics'],
  },
  {
    icon: NewsIcon,
    text: 'News',
    url: '/news/smart-on',
    menu: ['Modes', 'Topics'],
  },
  {
    icon: GraphIcon,
    text: 'Graph',
    url: '/graph/queries?graphId=latest',
    menu: ['Modes', 'Topics'],
  },
  {
    icon: PulseIcon,
    text: 'Pulse',
    url: '/pulse',
    menu: ['Modes', 'Topics'],
  },
]

export const sideBarModes = [
  {
    name: 'A',
    subItems: [
      { name: 'AA' },
      { name: 'AB' },
      { name: 'AC' },
      { name: 'AD' },
      { name: 'AE' },
      { name: 'AF' },
    ],
  },
  {
    name: 'B',
    subItems: [
      { name: 'BA' },
      { name: 'BB' },
      { name: 'BC' },
      { name: 'BD' },
      { name: 'BE' },
      { name: 'BF' },
    ],
  },
  {
    name: 'C',
    subItems: [
      { name: 'CA' },
      { name: 'CB' },
      { name: 'CC' },
      { name: 'CD' },
      { name: 'CE' },
      { name: 'CF' },
    ],
  },
  {
    name: 'D',
    subItems: [
      { name: 'DA' },
      { name: 'DB' },
      { name: 'DC' },
      { name: 'DD' },
      { name: 'DE' },
      { name: 'DF' },
    ],
  },
  {
    name: 'E',
    subItems: [
      { name: 'EA' },
      { name: 'EB' },
      { name: 'EC' },
      { name: 'ED' },
      { name: 'EE' },
      { name: 'EF' },
    ],
  },
  {
    name: 'F',
    subItems: [
      { name: 'FA' },
      { name: 'FB' },
      { name: 'FC' },
      { name: 'FD' },
      { name: 'FE' },
      { name: 'FF' },
    ],
  },
]
