import MyFeedRedIcon from '@/assets/img/news/myFeed/myFeed_red_icon.svg'
import MyFeedGrayIcon from '@/assets/img/news/myFeed/myFeed_gray_icon.svg'
import TopicsGrayIcon from '@/assets/img/news/topics/topics_gray_icon.svg'
import TopicsRedIcon from '@/assets/img/news/topics/topics_red_icon.svg'
import NarrativesGrayIcon from '@/assets/img/news/narratives/narratives_gray_icon.svg'
import NarrativesRedIcon from '@/assets/img/news/narratives/narratives_red_icon.svg'
import FormatsGrayIcon from '@/assets/img/news/formats/formats_gray_icon.svg'
import FormatsRedIcon from '@/assets/img/news/formats/formats_red_icon.svg'
import HashtagsGrayIcon from '@/assets/img/news/hashtags/hashtags_gray_icon.svg'
import HashtagsRedIcon from '@/assets/img/news/hashtags/hashtags_red_icon.svg'
import ZonesGrayIcon from '@/assets/img/news/zones/zones_gray_icon.svg'
import ZonesRedIcon from '@/assets/img/news/zones/zones_red_icon.svg'
import PublishersGreyIcon from '@/assets/img/news/publishers/publishers_grey_18x18.svg'
import PublishersRedIcon from '@/assets/img/news/publishers/publishers_red_18x18.svg'

const coverageTitle =
  'Team USA basketball vs. France score, Tokyo Olympics: United States suffers'

export const newsTabLists = [
  {
    icon: MyFeedGrayIcon,
    activeIcon: MyFeedRedIcon,
    name: 'My Feed',
    url: '/news/my-feed',
  },
  {
    icon: TopicsGrayIcon,
    activeIcon: TopicsRedIcon,
    name: 'Topics',
    url: '/news/topics',
  },
  {
    icon: NarrativesGrayIcon,
    activeIcon: NarrativesRedIcon,
    name: 'Narratives',
    url: '/news/narratives',
  },
  {
    icon: FormatsGrayIcon,
    activeIcon: FormatsRedIcon,
    name: 'Formats',
    url: '/news/formats',
  },
  {
    icon: HashtagsGrayIcon,
    activeIcon: HashtagsRedIcon,
    name: 'Hashtags',
    url: '/news/hashtags',
  },
  {
    icon: ZonesGrayIcon,
    activeIcon: ZonesRedIcon,
    name: 'Zones',
    url: '/news/zones',
  },
]

export const newsTabListsOff = [
  {
    icon: MyFeedGrayIcon,
    activeIcon: MyFeedRedIcon,
    name: 'My Feed',
    url: '/news/my-feed',
  },
  {
    icon: TopicsGrayIcon,
    activeIcon: TopicsRedIcon,
    name: 'Topics',
    url: '/news/topics',
  },
  {
    icon: PublishersGreyIcon,
    activeIcon: PublishersRedIcon,
    name: 'Publishers',
    url: '/news/publishers',
  },
  {
    icon: FormatsGrayIcon,
    activeIcon: FormatsRedIcon,
    name: 'Publisher Topics',
    url: '/news/publisher-topics',
  },
  {
    icon: HashtagsGrayIcon,
    activeIcon: HashtagsRedIcon,
    name: 'Hashtags',
    url: '/news/hashtags',
  },
  {
    icon: ZonesGrayIcon,
    activeIcon: ZonesRedIcon,
    name: 'Zones',
    url: '/news/zones',
  },
]

export const newsCategories = [
  'default',
  'politician',
  'criminologist',
  'politics',
  'analyst',
]

export const newsSubCategories = [
  'General',
  'Running News',
  'Running News',
  'Running News',
  'Running News',
  'Running News',
  'Running News',
  'Running News',
]
export const myFeedData = {}

export const topicsData = {
  coverage: [
    {
      title: coverageTitle,
      formatButton: {
        name: 'News Reporting',
        type: 'orange',
      },
    },
    {
      title: coverageTitle,
      formatButton: {
        name: 'Advertorial',
        type: 'pink',
      },
    },
    {
      title: coverageTitle,
      formatButton: {
        name: 'Personal Essay',
        type: 'cyan',
      },
    },
    {
      title: coverageTitle,
      formatButton: {
        name: 'Analysis',
        type: 'green',
      },
    },
    {
      title: coverageTitle,
      formatButton: {
        name: 'Review',
        type: 'purple',
      },
    },
    {
      title: coverageTitle,
      formatButton: {
        name: 'News Reporting',
        type: 'orange',
      },
    },
    {
      title: coverageTitle,
      formatButton: {
        name: 'Advertorial',
        type: 'pink',
      },
    },
    {
      title: coverageTitle,
      formatButton: {
        name: 'Personal Essay',
        type: 'cyan',
      },
    },
    {
      title: coverageTitle,
      formatButton: {
        name: 'Analysis',
        type: 'green',
      },
    },
    {
      title: coverageTitle,
      formatButton: {
        name: 'Review',
        type: 'purple',
      },
    },
  ],
  details: {
    title:
      'MindBio secures ethics approval for advanced stage cancer therapy trial testing zones',
    descriptions: [
      'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for the treatment of patients with advanced-stage cancer.',
      'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
      "In a significant development in the field of cancer research, MindBio, a leading biotech company, has successfully secured ethics approval for its groundbreaking advanced stage cancer therapy trial. This milestone marks a crucial step forward in the battle against cancer, offering new hope for patients with advanced-stage malignancies. The approval paves the way for conducting rigorous clinical trials that will evaluate the safety and efficacy of MindBio's innovative therapy, potentially revolutionizing cancer treatment.",
      'Meaning Centred Psychotherapy is a psychotherapeutic intervention used to treat advanced cancer patients living with symptoms of anxiety and or depression.',
    ],
    subArticle: [
      {
        title: 'The Advanced Stage Cancer Therapy Assessment Theory',
        description:
          "MindBio's advanced stage cancer therapy represents a paradigm shift in cancer treatment by leveraging innovative techniques and cutting-edge technologies. While specific details about the therapy remain confidential due to ongoing research and intellectual property concerns, the approved trial aims to assess its effectiveness in treating patients with advanced-stage cancer who have limited treatment options. The trial design incorporates a comprehensive evaluation of the therapy's safety, tolerability, and its potential to induce tumor regression or disease stabilization. Rigorous monitoring and data collection mechanisms will be in place to assess both short-term and long-term outcomes, ensuring a robust evaluation of the therapy's efficacy and potential side effects. Implications for Cancer Treatment",
      },
      {
        title: 'Implications for Cancer Treatment',
        description:
          "The approval of MindBio's advanced stage cancer therapy trial holds immense promise for patients facing dire prognoses. Conventional treatments often offer limited options and variable outcomes in advanced-stage cancers, underscoring the urgent need for innovative therapeutic approaches. MindBio's novel therapy, if successful, could revolutionize cancer treatment by providing an alternative for patients who have exhausted conventional options. Its targeted approach, based on the latest scientific insights, holds the potential to improve survival rates, enhance quality of life, and pave the way for more personalized and precise cancer therapies in the future.",
      },
    ],
    summary: [
      'MindBio Therapeutics has received ethics approval for a Phase II trial using microdoses of LSD and Meaning Centred Psychotherapy for advanced-stage cancer.',
      'The trial will assess the safety and effectiveness of this novel therapy, potentially offering new treatment options.',
      'This could revolutionize cancer treatment, providing alternatives for patients and paving the way for more personalized therapies.',
    ],
  },
  aiChat: {
    menuList: ['Recommended', 'Trending'],
    trending: [
      "'Anybody but him': GOP senators go public with disdain for Trump",
      'NY Times reporter reveals how Trump advisers were feeling reporter reveals how Trump advisers were feeling',
      'Elon Musk finds new Twitter CEO',
      'The COVID Public Health Emergency declaration has ended.',
      "JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand dy but him': GOP senators go p",
      'NY Times reporter reveals how Trump advisers were feeling reporter reveals how Trump advisers were feeling',
      'Elon Musk finds new Twitter CEO',
      'The COVID Public Health Emergency declaration has ended.',
      "JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand dy but him': GOP senators go p",
      'Elon Musk finds new Twitter CEO',
      'The COVID Public Health Emergency declaration has ended.',
      "JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand dy but him': GOP senators go p",
      'NY Times reporter reveals how Trump advisers were feeling reporter reveals how Trump advisers were feeling',
      'Elon Musk finds new Twitter CEO',
      'The COVID Public Health Emergency declaration has ended.',
      "JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand dy but him': GOP senators go p",
      'Elon Musk finds new Twitter CEO',
      'The COVID Public Health Emergency declaration has ended.',
      "JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand dy but him': GOP senators go p",
      'NY Times reporter reveals how Trump advisers were feeling reporter reveals how Trump advisers were feeling',
      'Elon Musk finds new Twitter CEO',
      'The COVID Public Health Emergency declaration has ended.',
      "JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand dy but him': GOP senators go p",
      'The COVID Public Health Emergency declaration has ended.',
      "JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand dy but him': GOP senators go p",
      'Elon Musk finds new Twitter CEO',
      'The COVID Public Health Emergency declaration has ended.',
      "JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand dy but him': GOP senators go p",
      'NY Times reporter reveals how Trump advisers were feeling reporter reveals how Trump advisers were feeling',
      'Elon Musk finds new Twitter CEO',
      'The COVID Public Health Emergency declaration has ended.',
      "JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand dy but him': GOP senators go p",
    ],
  },
  mediaSpotLight: {
    topicList: [
      {
        title: 'Roche Holding AG',
        subject: 'Cander',
        metidaMentions: 22467,
        positive: 89,
      },
      {
        title: 'Ethical Milestones',
        subject: 'Cander',
        metidaMentions: 22467,
        positive: 89,
      },
      {
        title: 'MindBio',
        subject: 'Cander',
        metidaMentions: 22467,
        positive: 89,
      },
      {
        title: 'AstraZeneca PLC',
        subject: 'Cander',
        metidaMentions: 22467,
        positive: 89,
      },
      {
        title: 'Immunotherapies',
        subject: 'Cander',
        metidaMentions: 22467,
        positive: 89,
      },
      {
        title: 'Roche Holding AG',
        subject: 'Cander',
        metidaMentions: 22467,
        positive: 89,
      },
      {
        title: 'Ethical Milestones',
        subject: 'Cander',
        metidaMentions: 22467,
        positive: 89,
      },
      {
        title: 'MindBio',
        subject: 'Cander',
        metidaMentions: 22467,
        positive: 89,
      },
      {
        title: 'AstraZeneca PLC',
        subject: 'Cander',
        metidaMentions: 22467,
        positive: 89,
      },
      {
        title: 'Immunotherapies',
        subject: 'Cander',
        metidaMentions: 22467,
        positive: 89,
      },
      {
        title: 'Roche Holding AG',
        subject: 'Cander',
        metidaMentions: 22467,
        positive: 89,
      },
      {
        title: 'Ethical Milestones',
        subject: 'Cander',
        metidaMentions: 22467,
        positive: 89,
      },
      {
        title: 'MindBio',
        subject: 'Cander',
        metidaMentions: 22467,
        positive: 89,
      },
      {
        title: 'AstraZeneca PLC',
        subject: 'Cander',
        metidaMentions: 22467,
        positive: 89,
      },
      {
        title: 'Immunotherapies',
        subject: 'Cander',
        metidaMentions: 22467,
        positive: 89,
      },
    ],
  },
}

export const hashtagData = {
  followingData: Array(8).fill({
    title: '#davanteadmas',
    buttonName: 'Following',
  }),
  followData: Array(8).fill({
    title: '#davanteadmas',
    buttonName: 'Follow',
  }),
}
