export const possibilityCards = {
  fl_writers: [
    {
      title: 'Grammar Checks',
      content:
        'Proofread and edit work, checking for typos, grammatical errors, and incorrect punctuation.',
      tag: 'Large Language Models (LLMs)',
    },
    {
      title: 'Colour Scheme Suggestions',
      content:
        'AI can suggest complementary colour palettes based on a selected colour or image, ensuring aesthetically pleasing design outcomes.',
      tag: 'Artificial General Intelligence (AGI)',
    },
    {
      title: 'Research Assistance',
      content:
        'Scan vast databases to gather research on any given topic, summarising the findings in a concise, accessible way.',
      tag: 'Natural Language Processing (NLP)',
    },
    {
      title: 'Style Assistance',
      content:
        "Analyse and replicate a writer's style, suggesting ways to make text more engaging or better aligned with a specific genre or audience.",
      tag: 'Natural Language Processing (NLP)',
    },
    {
      title: 'Idea Generation',
      content:
        "brainstorm unique plot ideas, character names, settings, and more, based on the writer's initial inputs or from an extensive database.",
      tag: 'Generative AI',
    },
    {
      title: 'Sentiment Analysis',
      content:
        'Analyse the emotional tone of a text, providing feedback on how it might be perceived by readers.',
      tag: 'Natural Language Processing (NLP)',
    },
    {
      title: 'Trend Analysis',
      content:
        'AI-journalism and media surveillance technology can monitor global trends, providing writers with up-to-date insights on reader preferences',
      tag: 'Natural Language Technology',
    },
    {
      title: 'Content Optimisation',
      content:
        'Optimise a piece for SEO, making it more visible to search engines and improving its online performance.',
      tag: 'Large Language Models (LLMs)',
    },
    {
      title: 'Text Generation',
      content:
        "Create drafts, outlines, or complete pieces of text, providing a starting point for writers or helping to overcome writer's block.",
      tag: 'Generative AI',
    },
    {
      title: 'Automate Transcriptions',
      content:
        'Transcribe voice notes into text, useful for capturing ideas or converting spoken interviews into written content.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'Summarisation',
      content:
        'Read and understand large documents or books, then summaries them concisely, aiding in research tasks.',
      tag: 'Natural Language Processing (NLP)',
    },
  ],
  fl_graphicdesigners: [
    {
      title: 'Color Scheme Suggestions',
      content:
        'Suggested color schemes based on a design brief or brand guidelines, offering harmonious color combinations.',
      tag: 'Generative AI',
    },
    {
      title: 'Logo Generation',
      content:
        'Create a multitude of logo designs based on certain input parameters, providing graphic designers with a broad range of initial concepts.',
      tag: 'Natural Language Processing (NLP)',
    },
    {
      title: 'Design Research',
      content:
        'Provide in-depth research on specific design topics, saving time and effort for the designer. track mentions or keywords of specific technologies, tools, design topics.',
      tag: 'Natural Language Processing (NLP)',
    },
    {
      title: 'Typography Selection',
      content:
        'Analyze a design brief and suggest suitable typefaces that complement the overall design, tone, and message.',
      tag: 'Artificial General Intelligence (AGI)',
    },
    {
      title: 'AI Illustrations',
      content:
        'Produce illustrations based on specific inputs, providing a starting point for designers.  ',
      tag: 'Generative AI',
    },
    {
      title: 'Image Retouching',
      content:
        'Automate tasks like removing blemishes, enhancing colors, or smoothing edges in digital images, saving graphic designers significant time.',
      tag: 'Artificial General Intelligence (AGI)',
    },
    {
      title: 'Content Generation',
      content:
        'Generate descriptive text content for designs, such as taglines, Headers, Body Text or captions.',
      tag: 'Generative AI',
    },
    {
      title: 'Automatic Template Generation',
      content:
        'Automatically generate a variety of design templates for different platforms, reducing repetitive work for designers.',
      tag: 'Generative AI',
    },
    {
      title: 'Automatic Design Feedback',
      content:
        'Analyze client feedback and break it down into actionable changes for the design.',
      tag: 'Natural Language Processing (NLP)',
    },
  ],
  fl_softwaredevelopers: [
    {
      title: 'Code Comments Generation',
      content:
        'Auto-generate code comments, aiding readability and maintainability of code.',
      tag: 'Generative AI',
    },
    {
      title: 'Automated Coding',
      content:
        'Generate or autocomplete code based on training from large codebases.',
      tag: 'Neural Networks',
    },
    {
      title: 'Media Surveillance',
      content:
        'Track mentions of specific technologies, tools, or software in the media, allowing developers to stay informed about their public image or the competition.',
      tag: 'Natural Language Processing (NLP)',
    },
    {
      title: 'Bug Detection',
      content:
        'AI can be trained to recognize patterns that typically lead to bugs, notifying the developer before the bug occurs.',
      tag: 'Natural Language Understanding (NLU)',
    },
    {
      title: 'Chat Automation',
      content:
        'Developers can Deploy an AI Agent to handle client engagement or design-related inquiries.',
      tag: 'Artificial General Intelligence (AGI)',
    },
    {
      title: 'Code Review',
      content:
        'Review existing code to identify inefficiencies or security vulnerabilities.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'Requirement Analysis',
      content:
        'Our tech can analyses and understand complex project requirements and suggest the best architectural or design patterns to apply.',
      tag: 'Generative AI',
    },
    {
      title: 'Software Maintenance',
      content:
        'Monitor software in real-time to detect and fix issues, thus minimizing downtime.',
      tag: 'Artificial General Intelligence (AGI)',
    },
    {
      title: 'Documentation',
      content:
        'Generate coherent and understandable software documentation from technical specifications.',
      tag: 'Generative AI',
    },
  ],
  fl_tutors: [
    {
      title: 'Grading',
      content:
        'Automate the grading of tests and assignments, freeing up time for the tutor.',
      tag: 'Natural Language Processing (NLP)',
    },
    {
      title: 'Lesson Planning',
      content:
        "Design individualized lessons, taking into account the student's unique learning style and subject strengths.",
      tag: 'Natural Language Technology',
    },
    {
      title: 'Personalised Learning',
      content:
        "Adapt learning materials to cater to each student's pace and level of understanding.",
      tag: 'Natural Language Processing (NLU)',
    },
    {
      title: 'Feedback',
      content:
        "Generate feedback for students' work, pinpointing areas of improvement.",
      tag: 'Generative AI',
    },
    {
      title: 'Knowledge Visualization',
      content:
        'interactive knowledge graph can aid in visualizing complex concepts, making them easier for students to understand.',
      tag: 'Large Language Models (LLMs)',
    },
    {
      title: 'Research Aid',
      content:
        'AI research platform can assist tutors in academic research by compiling and organising relevant information from various sources.',
      tag: 'Large Language Models (LLMs)',
    },
    {
      title: 'Content Creation',
      content:
        'Our tech can analyses and understand complex project requirements and suggest the best architectural or design patterns to apply.',
      tag: 'Generative AI',
    },
    {
      title: 'Resource Recommendation',
      content:
        "Suggest relevant teaching resources and materials based on each student's needs and the tutor's teaching style.",
      tag: 'Natural Language Technology',
    },
  ],
  fl_dataanalysts: [
    {
      title: 'Predictive Analysis',
      content:
        'Generate predictive models using historical data, which assists in forecasting future trends and patterns.',
      tag: 'Generative AI',
    },
    {
      title: 'Data Mining',
      content:
        'Uncover patterns and relationships within large datasets that might not be evident to human analysts.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'Data Cleaning',
      content:
        'Automate the process of identifying and rectifying erroneous or incomplete data.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'Anomaly Detection',
      content:
        'Identify unusual data points or trends that might signify important events or anomalies.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'Data Integration',
      content:
        'Combine data from diverse sources into a coherent, usable dataset.',
      tag: 'Artificial General Intelligence (AGI)',
    },
    {
      title: 'Summarization',
      content:
        'Summaries research findings, saving analysts the time of reading through complex studies.',
      tag: 'Natural Language Generation (NLG)',
    },
    {
      title: 'Time Series Analysis',
      content:
        'Model and forecast time-dependent data, such as sales or stocks, to predict future values.',
      tag: 'Large Language Models (LLMs)',
    },
    {
      title: 'Report Generation',
      content:
        'Generate comprehensive reports of data analysis outcomes, highlighting key insights and conclusions.',
      tag: 'Generate comprehensive reports of data analysis outcomes, highlighting key insights and conclusions.',
    },
    {
      title: 'Data Visualisation',
      content:
        'Create intuitive and insightful visual representations of complex datasets to simplify data interpretation.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'Content Generation',
      content:
        'Generate readable reports or narratives from the raw data, simplifying the communication of results.',
      tag: 'Generative AI',
    },
  ],
  fl_marketingspecialists: [
    {
      title: 'Content Creation',
      content:
        'Create engaging copy for blogs, ads, social media, enhancing brand communication.',
      tag: 'Generative AI',
    },
    {
      title: 'Social Media Analysis',
      content:
        'Analysing patterns, AI can identify trending topics, sentiment, and user behavior for enhanced social media marketing.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'News Briefing',
      content:
        'Provide real-time market news, keeping marketers updated on relevant trends and events.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'Text Analytics',
      content:
        'analyse customer feedback, reviews, and queries to derive actionable insights.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'Pricing Optimization',
      content:
        'Analyse market and customer data to optimise product pricing, maximizing profit and competitiveness.',
      tag: 'Large Language Models (LLMs)',
    },
    {
      title: 'Email Marketing',
      content:
        'Can personalise and optimise email campaigns, increasing open rates and conversions.',
      tag: 'Natural Language Understanding (NLU)',
    },
    {
      title: 'media Listening',
      content:
        'Monitor various media channels to capture and analyse brand mentions, providing real-time insights on public opinion.',
      tag: 'Large Language Models (LLMs)',
    },
    {
      title: 'Narrative Tracking',
      content:
        'Track narratives across different platforms and media channels, helping understand shifting sentiment towards your brand.',
      tag: 'Large Language Models (LLMs)',
    },
    {
      title: 'Predictive Analytics',
      content:
        'predict customer behaviour and market trends, facilitating data-driven decision making.',
      tag: 'Neural Networks',
    },
    {
      title: 'SEO',
      content:
        'Optimise website content for search engines, improving website ranking and visibility.',
      tag: 'Generative AI',
    },
  ],
  fl_accountants: [
    {
      title: 'Expense Tracking',
      content:
        'Categories expenses automatically and track them for accounting and tax purposes.',
      tag: 'Natural Language Understanding (NLU)',
    },
    {
      title: 'Invoice processing',
      content:
        'Automate the process of entering data from invoices, reducing manual work and errors.',
      tag: 'Artificial General Intelligence (AGI)',
    },
    {
      title: 'Financial Forecasting',
      content:
        'Analyses financial trends and data to predict future financial outcomes.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'Payroll management',
      content:
        'Automated systems can calculate salaries, deductions, and generate paylist.',
      tag: 'Artificial General Intelligence (AGI)',
    },
    {
      title: 'Audit support',
      content:
        'Review financial documents to support the auditing process, reducing human effort and errors.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'tax preparation',
      content:
        'Collate data, apply tax codes, and generate accurate tax returns.',
      tag: 'Artificial General Intelligence (AGI)',
    },
    {
      title: 'budget planning',
      content: 'Analyse past spending to help devise future budgets.',
      tag: 'Large Language Models (LLMs)',
    },
    {
      title: 'real-time accounting',
      content:
        "Provide real-time updates and alerts on the company's financial status.",
      tag: 'Large Language Models (LLMs) AI',
    },
  ],
  fl_journalists: [
    {
      title: 'Research assistance',
      content:
        'Interactive knowledge graphs can identify and visualise connections between different data points or entities, aiding in investigative journalism',
      tag: 'Neural Networks',
    },
    {
      title: 'fact-checking',
      content:
        'Cross-reference information in an article against reliable sources to check for accuracy.',
      tag: 'Natural Language Technology AI',
    },
    {
      title: 'automated summaries',
      content:
        'AI can create concise summaries of lengthy documents, saving research time.',
      tag: 'Natural Language Generation (NLG)',
    },
    {
      title: 'creative writing',
      content:
        'Assist in writing speeches, opinion pieces, blogs or editorials, generating draft text based on input data and stylistic preferences.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'plagiarism detection',
      content:
        'Identify similarities between text, helping detect potential instances of plagiarism.',
      tag: 'Natural Language Processing (NLP)',
    },
    {
      title: 'trend spotting',
      content:
        'Analyse patterns in social media or search engine data to predict emerging trends or stories',
      tag: 'Natural Language Technology',
    },
    {
      title: 'data analysis',
      content:
        'Analyse large datasets and provide insights for journalists to write about.',
      tag: 'Large Language Models (LLMs)',
    },
    {
      title: 'news aggregation',
      content:
        'Aggregate news from different sources, presenting journalists with a broad view of current topics.',
      tag: 'Large Language Models (LLMs)',
    },
    {
      title: 'automated news generation',
      content:
        'Generate articles or reports from data input, often used in areas like financial or sports reporting.',
      tag: 'Generative AI',
    },
  ],
  fl_traders: [
    {
      title: 'Data Analysis',
      content:
        'process vast quantities of financial data, extracting key insights and spotting patterns.',
      tag: 'Large Language Models (LLMs)',
    },
    {
      title: 'media surveillance',
      content:
        'Cross-reference information in an article against reliable sources to check for accuracy.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'algorithmic trading',
      content:
        'Facilitate automated trading based on predefined algorithms, minimising human error.',
      tag: 'Neural Networks',
    },
    {
      title: 'news briefing',
      content:
        'AI journalism can generate real-time news briefings, informing traders of latest market and event developments.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'predictive modelling',
      content:
        'Generative AI and neural networks can predict future market trends, currency values, or sporting outcomes based on past data.',
      tag: 'Neural Networks',
    },
    {
      title: 'event prediction',
      content:
        'Novel AGI systems can predict market-moving events by analysing vast amounts of data from various sources.',
      tag: 'Artificial General Intelligence (AGI)',
    },
    {
      title: 'data analysis',
      content:
        'Process vast quantities of financial, sporting or currency data, extracting key insights and spotting patterns.',
      tag: 'Large Language Models (LLMs)',
    },
    {
      title: 'research platform',
      content:
        'AI can serve as a research platform, providing in-depth analysis of companies, industries, and market trends.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'sentiment analysis',
      content:
        'Analyse global sentiment from tens of thousands of sources, offering valuable insights into market dynamics and the broader emotional drivers of society',
      tag: 'Generative AI',
    },
  ],
  fl_seospecialists: [
    {
      title: 'SEO Auditing',
      content:
        'Conduct a detailed SEO audit of a website to identify weaknesses and areas for improvement.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'keyword research',
      content:
        "Automate the process of finding relevant and high-volume keywords related to a website's content.",
      tag: 'Artificial General Intelligence (AGI)',
    },
    {
      title: 'content generation',
      content:
        'Create SEO-optimised content, including blog posts, advertorials and product descriptions.',
      tag: 'Generative AI',
    },
    {
      title: 'link building',
      content:
        'Automate the discovery of potential backlink opportunities and monitor the health of existing links.',
      tag: 'Large Language Models (LLMs)',
    },
    {
      title: 'competitor analysis',
      content:
        "Analyse competitors' sEO Strategies and provide insights on areas where your site can improve.",
      tag: 'Neural Networks',
    },
    {
      title: 'Neural Networks',
      content:
        'Automate the addition of structured data or schema markup to help search engines better understand the content.',
      tag: 'Artificial General Intelligence (AGI)',
    },
    {
      title: 'topic modelling',
      content:
        'Identify trending topics for content creation to boost organic traffic.',
      tag: 'Large Language Models (LLMs)',
    },
    {
      title: 'meta tag generation',
      content:
        'Generate SEO-friendly meta tags and descriptions for web pages to improve search engine visibility.',
      tag: 'Generative AI',
    },
    {
      title: 'duplicate content detection',
      content:
        'AI can scan a website to detect duplicate content that might harm SEO performance.',
      tag: 'Large Language Models (LLMs)',
    },
  ],
  fl_researchers: [
    {
      title: 'Literature review',
      content:
        'Quickly review thousands of articles and documents, extracting relevant information to support research work.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'data analysis',
      content:
        'Efficiently process, sort, and analyse large sets of data, revealing patterns and correlations.',
      tag: 'Generative AI',
    },
    {
      title: 'research summarisation',
      content:
        'Produce concise summaries of extensive research papers or reports.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'visualisation',
      content:
        'Generate graphics, charts, and figures to visually represent data and research findings.',
      tag: 'Large Language Models (LLMs)',
    },
    {
      title: 'Grant proposal writing',
      content:
        'AI can assist in drafting grant proposals, ensuring compliance with guidelines and enhancing chances of approval.',
      tag: 'Generative AI',
    },
    {
      title: 'content creation',
      content:
        "Draft reports, articles, skeleton templates or research papers, freeing up the researcher's time for other tasks.",
      tag: 'Generative AI',
    },
    {
      title: 'scheduling',
      content:
        "Autonomously manage a researcher's schedule, arranging meetings, deadlines, and reminders for tasks.",
      tag: 'Artificial General Intelligence (AGI)',
    },
    {
      title: 'daily news briefing',
      content:
        'News briefing technology can curate relevant news for the researcher, keeping them up-to-date in their field.',
      tag: 'Generative AI',
    },
    {
      title: 'text analytics',
      content:
        'Analyse written text, extracting key themes, topics, and entities.',
      tag: 'Natural Language Processing (NLP)',
    },
  ],
  fl_socialmediamanager: [
    {
      title: 'Trend Identification',
      content:
        'Detecting emerging trends in the social and digital media landscapes to stay ahead of the curve.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'content curation',
      content:
        'Sourcing and arranging relevant content from various sources to post on social media.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'crisis management',
      content:
        'Monitoring for potential crises or negative sentiment spikes, and alerting the manager.',
      tag: 'Natural Language Processing (NLP)',
    },
    {
      title: 'demographic analysis',
      content:
        'Examining follower demographics to tailor content and campaigns accordingly.',
      tag: 'Neural Networks',
    },
    {
      title: 'keyword tracking',
      content:
        'Monitoring specific keywords to gauge brand, topic or campaign awareness around the clock.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'competition analysis',
      content:
        "Keeping track of competitors' social media activities to inform strategy.",
      tag: 'Natural Language Technology',
    },
    {
      title: 'content generation',
      content:
        'Creating high-quality, engaging written and visual content for social media platforms.',
      tag: 'Generative AI',
    },
    {
      title: 'Autonomous account management',
      content:
        'Autonomously schedule, draft and post on social media at optimal times based on past engagement data.',
      tag: 'Artificial General Intelligence (AGI)',
    },
  ],
  fl_virtualassistants: [
    {
      title: 'Media Surveillance',
      content:
        'monitor various sources to track brand mentions, competitor activities, or industry developments, aiding strategic decision-making.',
      tag: 'Large Language Models (LLMs)',
    },
    {
      title: 'Text Generation',
      content:
        'draft emails, messages, and other forms of written communication, mimicking human-like language and style for various contexts and audiences.',
      tag: 'Generative AI',
    },
    {
      title: 'Meeting Summarization',
      content:
        'Summaries key points and action items from recorded meetings or notes.',
      tag: 'Natural Language Processing (NLP) AI',
    },
    {
      title: 'News Briefings',
      content:
        'Draft emails, messages, and other forms of written communication, mimicking human-like language and style for various contexts and audiences.',
      tag: 'Large Language Models (LLMs)',
    },
    {
      title: 'Email Management',
      content:
        'organise, sort and prioritise emails based on importance, and even respond to simple emails using pre-determined responses.',
      tag: 'Artificial General Intelligence (AGI)',
    },
    {
      title: 'Document Creation',
      content:
        'Generate reports, memos, or presentations, based on provided data or prompts.',
      tag: 'Generative AI',
    },
    {
      title: 'Schedule Optimization',
      content:
        "Optimise an executive's schedule, considering meeting timings, travel time, and personal preferences.",
      tag: 'Natural Language Technology',
    },
    {
      title: 'Schedule Coordination',
      content:
        'Text manage calendars, set up meetings and appointments, and send reminders, adjusting for conflicts and optimal timings.',
      tag: 'Artificial General Intelligence (AGI)',
    },
  ],
  fl_consultants: [
    {
      title: 'Personalized Research',
      content:
        "LLMs can sift through extensive databases and deliver tailored research reports, enhancing the efficiency and depth of consultants' research.",
      tag: 'Large Language Models (LLMs)',
    },
    {
      title: 'Market Research',
      content:
        'Automate the collection and analysis of market data, identifying patterns and trends to assist consultants in business decision-making.',
      tag: 'Artificial General Intelligence (AGI)',
    },
    {
      title: 'Predictive Modelling',
      content:
        'Develop predictive models to forecast business scenarios, enabling consultants to anticipate market movements and make strategic decisions.',
      tag: 'Neural Networks',
    },
    {
      title: 'Data Analysis',
      content:
        'Manage and analyse large sets of data to identify patterns, providing consultants with valuable insights and potential business strategies.',
      tag: 'Generative AI',
    },
    {
      title: 'Content Generation',
      content:
        'Automatically create reports, articles, or other types of content, freeing consultants from time-consuming writing tasks.',
      tag: 'Generative AI',
    },
    {
      title: 'Customer Segementation',
      content:
        'Aanage and analyse large sets of data to identify patterns, providing consultants with valuable insights and potential business strategies.',
      tag: 'Large Language Models (LLMs)',
    },
    {
      title: 'Competitive Analysis',
      content:
        'Continually monitor competitors, analysing their strategies and performance, offering consultants valuable insights into the competitive landscape.',
      tag: 'Natural Language Technology AI',
    },
  ],
  fl_translators: [
    {
      title: 'Automated Transcription',
      content:
        'Transcribe multilingual audio, converting it into written text, a useful tool for translators working with audio-visual material',
      tag: 'Large Language Models (LLMs)',
    },
    {
      title: 'Summary Generation',
      content:
        'Generate summarised versions of long texts, assisting translators in grasping the overall content swiftly.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'Proofreading',
      content:
        'Groofread translated texts, checking for grammatical and syntactic errors and helping to ensure accuracy.',
      tag: 'Natural Language Understanding (NLU)',
    },
    {
      title: 'Language Style Adaptation',
      content:
        'Adjust the style of translated text to match the desired tone or register, supporting translators in delivering content that fits the intended audience.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'Glossary Building',
      content:
        'Build glossaries for specific domains, automatically identifying and compiling relevant terminology and phrases.',
      tag: 'Generative AI',
    },
    {
      title: 'Translation',
      content:
        'Translate large volumes of text swiftly, providing the first draft that the translator can refine further.',
      tag: 'Generative AI',
    },
    {
      title: 'Cultural Reference Handling',
      content:
        'Understand and provide suggestions for translating or localising cultural references, ensuring that the translation is contextually appropriate.',
      tag: 'Natural Language Technology',
    },
  ],
  fl_photographers: [
    {
      title: 'Image Categorization',
      content:
        'Automatically organise and categories vast libraries of images based on specific attributes such as subject, location, or color.',
      tag: 'Large Language Models (LLMs)',
    },
    {
      title: 'Text Generation',
      content:
        'Create descriptive text for images, useful for captions or image metadata.',
      tag: 'Generative AI',
    },
    {
      title: 'location scouting',
      content:
        "Analyse geographical data, weather conditions, and local points of interest to suggest optimal locations for a shoot. Using information from mapping services, historical data and other photographers' location tags",
      tag: 'Natural Language Processing (NLP)',
    },
    {
      title: 'Image Synthesis',
      content:
        'create new, unique images based on specified parameters, useful for concept photography.',
      tag: 'Large Language Models (LLMs)',
    },
    {
      title: 'automatic photo editing',
      content:
        'Carry out basic photo editing tasks such as cropping, resizing, or red-eye removal.',
      tag: 'Artificial General Intelligence (AGI)',
    },
    {
      title: 'predictive analytics',
      content:
        'predict the best times and locations for taking photographs based on weather data and lighting conditions.',
      tag: 'Generative AI',
    },
    {
      title: 'copyright protection',
      content:
        "track and identify unauthorised use of a photographer's work on the internet, providing alerts when infringements occur.",
      tag: 'Natural Language Technology',
    },
    {
      title: 'Trend Research',
      content:
        'gather insights into current photography, geographical or culural trends, helping photographers stay relevant.',
      tag: 'Artificial General Intelligence (AGI)',
    },
  ],
  fl_lawyers: [
    {
      title: 'Document Review',
      content:
        'Analyse legal documents swiftly, reducing the time required for manual review.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'Legal Update',
      content: 'Track and notify about new laws or changes in existing laws.',
      tag: 'Natural Language Processing (NLP)',
    },
    {
      title: 'Legal Research',
      content:
        'Search through vast amounts of case law and statutes, significantly improving research efficiency.',
      tag: 'Natural Language Processing (NLP)',
    },
    {
      title: 'contract analysis',
      content: 'Review and analyse contracts, identifying potential issues.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'due diligence',
      content:
        'Expedite the process of investigating facts of a case or a business deal.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'drafting documents',
      content:
        'Generate initial drafts of legal documents like contracts or letters.',
      tag: 'Generative AI',
    },
    {
      title: 'transcriptions',
      content:
        'Transcribe legal proceedings or meetings, converting speech to text.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'narrative tracking',
      content:
        'Track media narratives surrounding a case, providing insight into public sentiment.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'legal summaries',
      content:
        'Summarise complex legal documents into simpler, understandable language.',
      tag: 'Large Language Models (LLMs)',
    },
  ],
  fl_prcomspecialists: [
    {
      title: 'draft press releases',
      content:
        'Create structured press releases based on data inputs,  incorporating necessary information and following industry standards.',
      tag: 'Natural Language Generation (NLG)',
    },
    {
      title: 'media monitoring',
      content:
        "Scan news sites, online social media platforms and forums to monitor the brand's media coverage and sentiment analysis.",
      tag: 'Natural Language Technology',
    },
    {
      title: 'crisis detection',
      content:
        'Identify potential crises early by analysing online sentiment and anomalies in brand discussions.',
      tag: 'Natural Language Understanding (NLU)',
    },
    {
      title: 'content generation',
      content:
        'Create blog articles, social media posts, editorial or newsletters based on given parameters.',
      tag: 'generative aI',
    },
    {
      title: 'narrative tracking',
      content:
        'Track the evolution of public narratives around a brand or topic over time.',
      tag: 'Natural Language Understanding (NLU)',
    },
    {
      title: 'speech writing',
      content:
        "Draft speeches by considering the speaker's style, the audience, and the event context.",
      tag: 'Generative AI',
    },
    {
      title: 'reputation management',
      content:
        'Track and analyse public opinion about a company, identifying areas that may need strategic communication efforts, then formulate a proposed remedy.',
      tag: 'Artificial General Intelligence (AGI)',
    },
    {
      title: 'brand voice development',
      content:
        'Generative AI can be trained to replicate and maintain a consistent brand voice across all communication.',
      tag: 'Generative AI',
    },
  ],
  fl_entrepreneurs: [
    {
      title: 'business Analysis',
      content:
        'Analyse complex business data and generate insights, enabling the entrepreneur to make informed decisions.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'personal assistant',
      content:
        'Manage schedules, set reminders, and organise tasks, helping the entrepreneur to stay focused and organised.',
      tag: 'Artificial General Intelligence (AGI)',
    },
    {
      title: 'content Creation',
      content:
        'Write blog posts, articles, newsletters or social media updates, saving time for the entrepreneur.',
      tag: 'Natural Language Understanding (NLU)',
    },
    {
      title: 'financial planning',
      content:
        'Analyse financial data and provide advice on investment decisions, budgeting, and financial risk management.',
      tag: 'Large Language Models (LLMs)',
    },
    {
      title: 'recruitment',
      content:
        'Sift through resumes, identify potential candidates, and even conduct initial interviews, making the hiring process more efficient.',
      tag: 'Natural Language Understanding (NLU)',
    },
    {
      title: 'market research',
      content:
        'Cour the internet to gather market intelligence, identifying trends and competitor strategies.',
      tag: 'Natural Language Processing (NLP)',
    },
    {
      title: 'competitor analysis',
      content:
        'AI-journalism can monitor and report on competitor activities, providing strategic insights.',
      tag: 'Natural Language Technology',
    },
    {
      title: 'data visualisation',
      content:
        'Turn complex data sets into understandable and interactive charts or graphs.',
      tag: 'Generative AI',
    },
    {
      title: 'news briefing',
      content:
        'Provide daily summaries of relevant industry news, ensuring the entrepreneur stays up-to-date with current events and market developments.',
      tag: 'Generative AI',
    },
  ],
}
