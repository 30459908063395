import BriefingIconPurple from '@/assets/img/dashboard/politician/mybriefing_hexagon_purple_16x16.svg'
import BriefingIconGreen from '@/assets/img/dashboard/politician/mybriefing_hexagon_green_16x16.svg'
import BriefingIconPink from '@/assets/img/dashboard/politician/mybriefing_hexagon_pink_16x16.svg'

// Swarm Active Jobs
import SwarmActiveJobsYellowIcon from '@/assets/img/icons/swarm_defining_yello_32x26.svg'
import SwarmActiveJobsPinkIcon from '@/assets/img/icons/swarm_executing_pink_32x26.svg'
import MonitoringGreeIcon from '@/assets/img/icons/swarm_evaluating_green_32x26.svg'

const briefingConent = [
  {
    icon: BriefingIconPurple,
    shadowColor: 'purple',
    description:
      'As artificial intelligence continues to gain prominence, businesses are increasingly looking to adopt AI solutions to drive innovation and gain a competitive edge.',
  },
  {
    icon: BriefingIconGreen,
    shadowColor: 'green',
    description:
      'Startups often have more flexibility and agility in experimenting with new pricing models.',
  },
  {
    icon: BriefingIconPink,
    shadowColor: 'pink',
    description:
      "Lastly, startups' ability to quickly adapt and iterate their pricing strategies allows them to stay ahead of market dynamics and rapidly respond to changing customer needs, further driving innovation in the AI tools pricing landscape.",
  },
]

const trendsContent = [
  "'Anybody but him': GOP senators go public with disdain for Trump",
  'NY Times reporter reveals how Trump advisers were feeling...',
  'Elon Musk finds new Twitter CEO',
  'The COVID Public Health Emergency declaration has ended.',
  'JPMorgan Chase CEO Jamie Dimon says Trump doesn’t understand...',
]

export const categoryData = {
  categories: ['default', 'politician', 'criminologist', 'politics', 'analyst'],
}

export const politicianData = {
  mybriefing: {
    hourly: briefingConent,
    daily: briefingConent,
    weekly: briefingConent,
  },
  activeJobs: {
    cardData: [
      {
        icon: SwarmActiveJobsYellowIcon,
        iconShadow: 'orange_shadow',
        title: 'Defining Job Tasks',
        trends: trendsContent,
      },
      {
        icon: SwarmActiveJobsPinkIcon,
        iconShadow: 'pink_shadow',
        title: 'Executing Job Tasks',
        trends: trendsContent,
      },
      {
        icon: SwarmActiveJobsYellowIcon,
        iconShadow: 'orange_shadow',
        title: 'Defining Job Tasks',
        trends: trendsContent,
      },
      {
        icon: SwarmActiveJobsPinkIcon,
        iconShadow: 'pink_shadow',
        title: 'Executing Job Tasks',
        trends: trendsContent,
      },
    ],
  },
  monitoring: {
    cardData: [
      {
        icon: MonitoringGreeIcon,
        iconShadow: 'green_shadow',
        title: "Active Smart <span style='color: #E93249'>Chats</span>",
        trends: trendsContent,
      },
      {
        icon: MonitoringGreeIcon,
        iconShadow: 'green_shadow',
        title: "Active <span style='color: #E93249'>Keyword</span> Tracking",
        trends: trendsContent,
      },
      {
        icon: MonitoringGreeIcon,
        iconShadow: 'green_shadow',
        title: "Active Smart <span style='color: #E93249'>Chats</span>",
        trends: trendsContent,
      },
      {
        icon: MonitoringGreeIcon,
        iconShadow: 'green_shadow',
        title: "Active <span style='color: #E93249'>Keyword</span> Tracking",
        trends: trendsContent,
      },
    ],
  },
  newsData: {
    narratives: {
      name: 'narratives',
      cardData: Array(4).fill({
        date: '8th Sep',
        contents: Array(5).fill(
          'Cancer therapy trials should not be allowed by the FED'
        ),
      }),
    },
    stories: {
      name: 'stories',
      cardData: Array(4).fill({
        date: '8th Sep',
        contents: Array(5).fill(
          'Cancer therapy trials should not be allowed by the FED'
        ),
      }),
    },
    articles: {
      name: 'articles',
      cardData: Array(4).fill({
        date: '8th Sep',
        contents: Array(5).fill(
          'Team USA basketball vs. France score, Tokyo Olympics: United States suffers'
        ),
      }),
    },
    aiArticles: {
      name: 'aiArticles',
      cardData: Array(4).fill({
        date: '8th Sep',
        contents: Array(5).fill(
          'Team USA basketball vs. France score, Tokyo Olympics: United States suffers'
        ),
      }),
    },
  },
}
