export const signUpData = [
  {
    title: 'Create your account',
    description:
      'Note that phone verification may be required for signup. Your number will only be used to verify your identity for security purposes.',
  },
  {
    title: 'SET your PASSWORD',
    description:
      'Note that phone verification may be required for signup. Your number will only be used to verify your identity for security purposes.',
  },
  {
    title: 'configure your profile',
  },
  {
    title: 'Pick your Subscription',
    subTitle: 'Start with 7 day free trial',
    description:
      'Pick your subscription tier to begin you obligation free trial',
  },
  {
    title: 'Payment Method',
    description: 'Finalise your subscription payment of',
  },
  {
    title: 'Welcome BACK',
  },
  {
    title: 'Welcome BACK',
    description: 'Check your email for confirmation code',
  },
]
export const memberShip = [
  {
    title: 'Freelancer',
    price: '$0.00+',
  },
  // {
  //   title: 'Pro',
  //   price: 99.99,
  // },
  // {
  //   title: 'Elite',
  //   price: 199.99,
  // },
]
