'use client' //this is a client side component

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  newPulseBlips: {
    isSet: false,
    item: null,
  },
  messages: [],
  isTyping: false,
  currentThread: null,
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setNewPulseBlipsInfo: (state, action) => {
      state.newPulseBlips = action.payload
    },
    addMessage: (state, action) => {
      state.messages.push(action.payload)
    },
    setIsTyping: (state, action) => {
      state.isTyping = action.payload
    },
    setCurrentThread: (state, action) => {
      state.currentThread = action.payload
    },
  },
})

export const { setNewPulseBlipsInfo, addMessage, setIsTyping, setCurrentThread } = chatSlice.actions

export default chatSlice.reducer
