export const panelData = {
  basic: {
    outColor: '#242832',
    inColor: '#10131d',
    paddingT: '20px',
    paddingB: '20px',
    paddingL: '20px',
    paddingR: '20px',
    thickness: '1px',
    borderRadius: '20px',
  },
}
