import React, { createContext, useEffect, useRef } from 'react'
import { useSessionContext } from '@supabase/auth-helpers-react'
import * as Comlink from 'comlink'

export const SharedWorkerContext = createContext(null)

export default ({ children }) => {
  let websocketWorkerObject = useRef(null)
  const { session } = useSessionContext()

  useEffect(() => {
    if (session) {
      if (window.SharedWorker) {
        const websocketWorker = new SharedWorker(
          new URL('./websocket.worker.js', import.meta.url),
          { type: 'module' }
        )

        websocketWorkerObject.current = Comlink.wrap(websocketWorker.port)

        initWebSocket(session.access_token)
      } else {
        console.error("Your browser doesn't support shared workers.")
      }
    }
  }, [session])

  const initWebSocket = async (accessToken) => {
    if (!window.SharedWorker) {
      console.error("Your browser doesn't support shared workers.")
      return
    }
    try {
      await websocketWorkerObject.current.initWebSocket(
        'wss://firesight-chat.firesight.workers.dev/api/room/lobby/websocket',
        accessToken
      )
    } catch (error) {
      console.error('Failed to initialize WebSocket:', error)
      // Implement reconnection logic here
    }
  }

  const sendMsg = (message) => {
    websocketWorkerObject.current.sendMessage(message)
  }

  const addMessageListener = (callback) => {
    websocketWorkerObject.current.addMessageListener(Comlink.proxy(callback))
  }

  return (
    <SharedWorkerContext.Provider
      value={{
        websocketWorkerObject,
        sendMsg,
        addMessageListener,
      }}
    >
      {children}
    </SharedWorkerContext.Provider>
  )
}
