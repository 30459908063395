/**
 * @format
 * @file: svg.js
 * @description: bunch of svg file
 * @author: Supernova
 */

// button svg
import CyanBtn_136x33 from '@/assets/img/buttonBg/cyan_136x33.svg'
import RedBtn_136x36 from '@/assets/img/buttonBg/red-136x36.svg'
import GreyBtn_136x36 from '@/assets/img/buttonBg/grey-136x36.svg'
import RedBtn_320x48 from '@/assets/img/buttonBg/polygonBtn-red-320x48.svg'
import CyberBtn_379x45 from '@/assets/img/buttonBg/cyber-379x45.svg'
import GreenBtn_174x36 from '@/assets/img/buttonBg/news_formatBtn_green_176x36.svg'
import GreenBtn_112x36 from '@/assets/img/buttonBg/green-112x36.svg'
import CyanBtn_88x36 from '@/assets/img/buttonBg/cyan-88x36.svg'
import GreenBtn_88x36 from '@/assets/img/buttonBg/green-88x36.svg'
import PurpleBtn_88x36 from '@/assets/img/buttonBg/purple-88x36.svg'
import YellowBtn_88x36 from '@/assets/img/buttonBg/yellow-88x36.svg'
import YellowBtn_168x33 from '@/assets/img/buttonBg/yellow-168x33.svg'
import ClipBtn_134x28 from '@/assets/img/buttonBg/clip_red_134x28.svg'

import GreyBtn_112x36 from '@/assets/img/buttonBg/grey_112x36.svg'
import TransBtn_146x36 from '@/assets/img/buttonBg/transparent_outline_146x36.svg'
import TransBtn_155x36 from '@/assets/img/buttonBg/transparent_outline_155x36.svg'
import GreyBtn_80x36 from '@/assets/img/buttonBg/grey_80x36.svg'
import RedBtn_88x36 from '@/assets/img/buttonBg/red_88x36.svg'
import TransBtn_184x33 from '@/assets/img/buttonBg/transparent_outline_184x33.svg'
import OrangeBtn_160x33 from '@/assets/img/buttonBg/orange_160x33.svg'
import PinkBtn_160x33 from '@/assets/img/buttonBg/pink_160x33.svg'
import CyanBtn_160x33 from '@/assets/img/buttonBg/cyan_160x33.svg'
import GreenBtn_160x33 from '@/assets/img/buttonBg/green_160x33.svg'
import PurpleBtn_160x33 from '@/assets/img/buttonBg/purple_160x33.svg'
import TransBtn_113x33 from '@/assets/img/buttonBg/transparent_outline_113x33.svg'
import TransBtn_152x33 from '@/assets/img/buttonBg/transparent_outline_152x33.svg'
import TransBtn_146x33 from '@/assets/img/buttonBg/transparent_outline_146x33.svg'
import TransBtn_143x33 from '@/assets/img/buttonBg/transparent_outline_143x33.svg'
import GreyBtn_113x33 from '@/assets/img/buttonBg/grey_113x33.svg'
import TransBtn_480x44 from '@/assets/img/buttonBg/transparent_outline_480x44.svg'
import TransBtn_516x44 from '@/assets/img/buttonBg/transparent_outline_516x44.svg'
import PinkBtn_169x45 from '@/assets/img/buttonBg/pink_169x45.svg'
import CyanBtn_169x45 from '@/assets/img/buttonBg/cyan_169x45.svg'
import YellowBtn_169x45 from '@/assets/img/buttonBg/yellow_169x45.svg'
import WhiteBtn_249x45 from '@/assets/img/buttonBg/white_249x45.svg'
import PinkBtn_249x45 from '@/assets/img/buttonBg/pink_249x45.svg'
import TransBtn_249x45 from '@/assets/img/buttonBg/transparent_outline_249x45.svg'
import TransBtn_280x34 from '@/assets/img/buttonBg/transparent_outline_280x34.svg'
import DottedPolygonBlackBg from '@/assets/img/buttonBg/black-dotted-88x413.svg'

import TransBtn_387x58 from '@/assets/img/buttonBg/transparent_outline_387x58.svg'
import TransBtn_295x52 from '@/assets/img/buttonBg/transparent_outline_295x52.svg'
import CyanBtn_208x36 from '@/assets/img/buttonBg/cyan_208x36.svg'
import RedBtn_144x36 from '@/assets/img/buttonBg/red-144x36.svg'

import BlackPolygon from '@/assets/img/buttonBg/black_polygon_88x413.svg'
import DottedPolygon_png from '@/assets/img/buttonBg/dotted_polygon.png'
import SimplePolygon_png from '@/assets/img/buttonBg/simpleplogyon.png'
import RedBtn_42x17 from '@/assets/img/buttonBg/red_42x17.svg'
import PinkBtn_42x17 from '@/assets/img/buttonBg/pink_42x17.svg'

// Static Buttons
// 1. Writers
import YellowBtn_174x47 from '@/assets/img/buttonBg/yellow_174x47.svg'
import PurpleBtn_219x47 from '@/assets/img/buttonBg/purple_219x47.svg'
import PinkBtn_210x47 from '@/assets/img/buttonBg/pink_210x47.svg'
import GreenBtn_174x47 from '@/assets/img/buttonBg/green_174x47.svg'
import PurpleBtn_192x47 from '@/assets/img/buttonBg/purple_192x47.svg'
import CyanBtn_183x47 from '@/assets/img/buttonBg/cyan_183x47.svg'
import CyanBtn_228x47 from '@/assets/img/buttonBg/cyan_228x47.svg'
import PinkBtn_183x47 from '@/assets/img/buttonBg/pink_183x47.svg'
import YellowBtn_255x47 from '@/assets/img/buttonBg/yellow_255x47.svg'
import GreenBtn_165x47 from '@/assets/img/buttonBg/green_165x47.svg'
//2. Graphic Designers
import YellowBtn_273x47 from '@/assets/img/buttonBg/yellow_273x47.svg'
import PurpleBtn_183x47 from '@/assets/img/buttonBg/purple_183x47.svg'
import PinkBtn_229x47 from '@/assets/img/buttonBg/pink_229x47.svg'
import GreenBtn_201x47 from '@/assets/img/buttonBg/green_201x47.svg'
import CyanBtn_192x47 from '@/assets/img/buttonBg/cyan_192x47.svg'
import PurpleBtn_210x47 from '@/assets/img/buttonBg/purple_210x47.svg'
import GreenBtn_309x47 from '@/assets/img/buttonBg/green_309x47.svg'
//3. Software Developers
import YellowBtn_264x47 from '@/assets/img/buttonBg/yellow_264x47.svg'
import CyanBtn_210x47 from '@/assets/img/buttonBg/cyan_210x47.svg'
import PinkBtn_165x47 from '@/assets/img/buttonBg/pink_165x47.svg'
import GreenBtn_183x47 from '@/assets/img/buttonBg/green_183x47.svg'
import CyanBtn_147x47 from '@/assets/img/buttonBg/cyan_147x47.svg'
import PurpleBtn_228x47 from '@/assets/img/buttonBg/purple_228x47.svg'
import YellowBtn_228x47 from '@/assets/img/buttonBg/yellow_228x47.svg'
//4. Tutors
import YellowBtn_111x47 from '@/assets/img/buttonBg/yellow_111x47.svg'
import PurpleBtn_237x47 from '@/assets/img/buttonBg/purple_237x47.svg'
import CyanBtn_156x47 from '@/assets/img/buttonBg/cyan_156x47.svg'
import PinkBtn_192x47 from '@/assets/img/buttonBg/pink_192x47.svg'
import GreenBtn_120x47 from '@/assets/img/buttonBg/green_120x47.svg'
import CyanBtn_255x47 from '@/assets/img/buttonBg/cyan_255x47.svg'
// 5. Data Analysts
import YellowBtn_219x47 from '@/assets/img/buttonBg/yellow_219x47.svg'
import PurpleBtn_165x47 from '@/assets/img/buttonBg/purple_165x47.svg'
import PinkBtn_201x47 from '@/assets/img/buttonBg/pink_201x47.svg'
import GreenBtn_192x47 from '@/assets/img/buttonBg/green_192x47.svg'
import YellowBtn_165x47 from '@/assets/img/buttonBg/yellow_165x47.svg'
import PurpleBtn_201x47 from '@/assets/img/buttonBg/purple_201x47.svg'
import YellowBtn_210x47 from '@/assets/img/buttonBg/yellow_210x47.svg'
import GreenBtn_210x47 from '@/assets/img/buttonBg/green_210x47.svg'
// 6. Marketing Specialists
import YellowBtn_192x47 from '@/assets/img/buttonBg/yellow_192x47.svg'
import GreenBtn_237x47 from '@/assets/img/buttonBg/green_237x47.svg'
import PinkBtn_174x47 from '@/assets/img/buttonBg/pink_174x47.svg'
import GreenBtn_228x47 from '@/assets/img/buttonBg/green_228x47.svg'
import YellowBtn_183x47 from '@/assets/img/buttonBg/yellow_183x47.svg'
import GreenBtn_75x47 from '@/assets/img/buttonBg/green_75x47.svg'
// 7. Accountants
// 8. Journalists
import CyanBtn_165x47 from '@/assets/img/buttonBg/cyan_165x47.svg'
// 10. Seo Specialists
import YellowBtn_156x47 from '@/assets/img/buttonBg/yellow_156x47.svg'
import GreenBtn_219x47 from '@/assets/img/buttonBg/green_219x47.svg'
import CyanBtn_174x47 from '@/assets/img/buttonBg/cyan_174x47.svg'
import PinkBtn_219x47 from '@/assets/img/buttonBg/pink_219x47.svg'
import YellowBtn_291x47 from '@/assets/img/buttonBg/yellow_291x47.svg'
// 11. Researchers
import PurpleBtn_246x47 from '@/assets/img/buttonBg/purple_246x47.svg'
import GreenBtn_246x47 from '@/assets/img/buttonBg/green_246x47.svg'
import CyanBtn_138x47 from '@/assets/img/buttonBg/cyan_138x47.svg'
// 12. Social Media Manager
import PurpleBtn_309x47 from '@/assets/img/buttonBg/purple_309x47.svg'
// 13. Virtual Assistants
import CyanBtn_237x47 from '@/assets/img/buttonBg/cyan_237x47.svg'
// 14. Translators
import PinkBtn_156x47 from '@/assets/img/buttonBg/pink_156x47.svg'
import GreenBtn_273x47 from '@/assets/img/buttonBg/green_273x47.svg'
import CyanBtn_201x47 from '@/assets/img/buttonBg/cyan_201x47.svg'
import PurpleBtn_147x47 from '@/assets/img/buttonBg/purple_147x47.svg'
// 15. Photographers
import GreenBtn_255x47 from '@/assets/img/buttonBg/green_255x47.svg'
// 16. Lawyers
import PurpleBtn_174x47 from '@/assets/img/buttonBg/purple_174x47.svg'
// 17. PR & Communications Specialists
import PurpleBtn_255x47 from '@/assets/img/buttonBg/purple_255x47.svg'
// 19 Entrepreneurs
import GreenBtn_147x47 from '@/assets/img/buttonBg/green_147x47.svg'
import CyanBtn_219x47 from '@/assets/img/buttonBg/cyan_219x47.svg'

// icon svg
import ArrowUpGrey_18x18 from '@/assets/img/icons/arrow_up_18x18.svg'
import ArrowDownGrey_18x18 from '@/assets/img/icons/arrow_down_18x18.svg'
import ArrowRightGrey_18x18 from '@/assets/img/icons/arrow_right_grey_24x24.svg'
import CloseGrey_18x18 from '@/assets/img/icons/close_grey_18x18.svg'
import CircledBack from '@/assets/img/backToModes.svg'
import DeleteGrey_24x24 from '@/assets/img/icons/delete_grey_24x24.svg'
import DocumentWhite_16x16 from '@/assets/img/icons/document_white_16x16.svg'
import GreyDelete_18x18 from '@/assets/img/icons/delete-grey-18x18.svg'
import InfoGrey_24x24 from '@/assets/img/icons/info_grey_24x24.svg'
import LightningYellow_32x26 from '@/assets/img/icons/lightning-yellow-32x26.svg'
import RedDelete_18x18 from '@/assets/img/icons/delete-red-18x18.svg'
import GreyDirectory_24x24 from '@/assets/img/icons/directory-grey-24x24.svg'
import PolyDevice_48x48 from '@/assets/img/icons/polygon-device-48x48.svg'
import PolyDirectory_48x48 from '@/assets/img/icons/polygon-directory-48x48.svg'
import GraphGrey_24x24 from '@/assets/img/icons/graph_grey_24x24.svg'
import YellowPlus_48x48 from '@/assets/img/icons/yellow-plus-48x48.svg'
import WhitePolygon_20x20 from '@/assets/img/icons/polygon-white-20x20.svg'
import RedCheck_16x16 from '@/assets/img/icons/check-red-16x16.svg'

import PlusRed_48x48 from '@/assets/img/icons/plus-red-48x48.svg'
import Drop_Down_White_14x14 from '@/assets/img/icons/drop_down_white_14x14.svg'
import Drop_Up_White_14x14 from '@/assets/img/icons/drop_up_white_14x14.svg'
import WorkGrey_24x24 from '@/assets/img/icons/work_grey_24x24.svg'
import PolygonGreyRedOutline_12x12 from '@/assets/img/icons/polygon_grey_redoutline_11x12.svg'
import PolygonRedFill_16x16 from '@/assets/img/icons/polygon_filled_red_16x16.svg'
import EmojiCryWhite_24x24 from '@/assets/img/icons/emoji_cry_white_24x24.svg'
import SliderPrevGrey_24x24 from '@/assets/img/icons/slider_prev_grey_24x24.svg'
import SliderNextGrey_24x24 from '@/assets/img/icons/slider_next_grey_24x24.svg'
import InfoRed_16x16 from '@/assets/img/icons/info_red_16x16.svg'
import PolygonPulse_40x40 from '@/assets/img/icons/polygon_pulse_40x40.svg'
import PolygonRedFill_16x18 from '@/assets/img/icons/polygon_filled_red_16x18.svg'
import PolygonGreyOutline_16x18 from '@/assets/img/icons/polygon_outline_grey_16x18.svg'
import TwitterWhitePolygonBg_24x24 from '@/assets/img/icons/twitter_polygonbg_white_24x24.svg'
import TimerRed_18x18 from '@/assets/img/icons/timer_red_18x18.svg'
import HexagonDownGrey_30x30 from '@/assets/img/icons/hexagon_down_grey_30x30.svg'
import HexagonUpGrey_30x30 from '@/assets/img/icons/hexagon_up_grey_30x30.svg'
import HexagonRingGrey_30x30 from '@/assets/img/icons/hexagon_ring_grey_30x30.svg'
import HexagonRingRed_30x30 from '@/assets/img/icons/hexagon_ring_red_30x30.svg'
import HexagonEmpty_30x30 from '@/assets/img/icons/hexagon_empty_30x30.svg'
import PolygonRedFill_18x20 from '@/assets/img/icons/polygon_filled_red_18x20.svg'
import PolygonGreyOutline_18x20 from '@/assets/img/icons/polygon_outline_grey_18x20.svg'
import CloseGrey_24x24 from '@/assets/img/icons/close_grey_24x24.svg'
import RingRed_24x24 from '@/assets/img/icons/ring_red_24x24.svg'
import XClose_10x10 from '@/assets/img/icons/x_close.svg'
import RedUpload_30x36 from '@/assets/img/icons/red-upload_30x36.svg'
import PulseGrey_24x24 from '@/assets/img/icons/pulse_grey_24x24.svg'
import EditWhite_16x16 from '@/assets/img/icons/edit_white_16x16.svg'
import HeartWhite_16x16 from '@/assets/img/icons/heart_white_16x16.svg'
import DeleteWhite_16x16 from '@/assets/img/icons/delete_white_16x16.svg'
import PolygonHOutlineGrey_24x24 from '@/assets/img/icons/polygon_horizon_outline_grey_24x24.svg'
import PolygonHRedFill_24x24 from '@/assets/img/icons/polygon_horizon_filled_red_24x24.svg'

import PolyDirectoryCyan_48x48 from '@/assets/img/icons/poly-directory-cyan-48x48.svg'
import PolyDirectoryGreen_48x48 from '@/assets/img/icons/poly-directory-green-48x48.svg'
import PolyDirectoryYellow_48x48 from '@/assets/img/icons/poly-directory-yellow-48x48.svg'
import PolyDirectoryPurple_48x48 from '@/assets/img/icons/poly-directory-purple-48x48.svg'
import BrainGreyPolygonOutline_24x24 from '@/assets/img/icons/brain_grey_polygon_outline_24x24.svg'
import BrainWhite_24x24 from '@/assets/img/icons/brain_white_24x24.svg'

import HiveWhite_12x12 from '@/assets/img/icons/hive_white_12x12.svg'
import SliderSquareRight_24x24 from '@/assets/img/carousel/arrow-square-right_24x24.svg'
import SliderSquareLeft_24x24 from '@/assets/img/carousel/arrow-square-left_24x24.svg'
import CheckGreyIcon_16x16 from '@/assets/img/icons/mark_16x16.svg'

// images
import Logo_270x324 from '@/assets/img/new_logo_270x324.svg'
import LogoStaticDesktop_64x64 from '@/assets/img/logo_static_desktop_64x64.svg'
import Logo_42x42 from '@/assets/img/logo_42x42.svg'
import Logo_30x36 from '@/assets/img/new_logo_30x36.svg'
import FooterLogo_134x110 from '@/assets/img/footer_logo_134x110.svg'

// exporting button's svg component
export {
  CyanBtn_136x33,
  CyanBtn_88x36,
  CyberBtn_379x45,
  GreenBtn_174x36,
  GreenBtn_112x36,
  GreenBtn_88x36,
  GreyBtn_136x36,
  PurpleBtn_88x36,
  RedBtn_136x36,
  RedBtn_320x48,
  YellowBtn_88x36,
  GreyBtn_112x36,
  TransBtn_146x36,
  TransBtn_155x36,
  GreyBtn_80x36,
  RedBtn_88x36,
  TransBtn_184x33,
  OrangeBtn_160x33,
  PinkBtn_160x33,
  CyanBtn_160x33,
  GreenBtn_160x33,
  PurpleBtn_160x33,
  TransBtn_113x33,
  TransBtn_152x33,
  TransBtn_146x33,
  TransBtn_143x33,
  GreyBtn_113x33,
  TransBtn_480x44,
  TransBtn_516x44,
  PinkBtn_169x45,
  CyanBtn_169x45,
  YellowBtn_169x45,
  WhiteBtn_249x45,
  PinkBtn_249x45,
  TransBtn_249x45,
  DottedPolygonBlackBg,
  TransBtn_387x58,
  TransBtn_295x52,
  CyanBtn_208x36,
  RedBtn_144x36,
  BlackPolygon,
  DottedPolygon_png,
  SimplePolygon_png,
  RedBtn_42x17,
  PinkBtn_42x17,
  ClipBtn_134x28,
  // Static Buttons
  YellowBtn_174x47,
  PurpleBtn_219x47,
  PinkBtn_210x47,
  GreenBtn_174x47,
  PurpleBtn_192x47,
  CyanBtn_183x47,
  CyanBtn_228x47,
  PinkBtn_183x47,
  YellowBtn_255x47,
  GreenBtn_165x47,
  TransBtn_280x34,
  YellowBtn_273x47,
  PurpleBtn_183x47,
  PinkBtn_229x47,
  GreenBtn_201x47,
  CyanBtn_192x47,
  PurpleBtn_210x47,
  GreenBtn_309x47,
  YellowBtn_264x47,
  CyanBtn_210x47,
  PinkBtn_165x47,
  GreenBtn_183x47,
  CyanBtn_147x47,
  PurpleBtn_228x47,
  YellowBtn_228x47,
  YellowBtn_111x47,
  YellowBtn_168x33,
  PurpleBtn_237x47,
  CyanBtn_156x47,
  PinkBtn_192x47,
  GreenBtn_120x47,
  CyanBtn_255x47,
  YellowBtn_219x47,
  PurpleBtn_165x47,
  PinkBtn_201x47,
  GreenBtn_192x47,
  YellowBtn_165x47,
  PurpleBtn_201x47,
  YellowBtn_210x47,
  GreenBtn_210x47,
  YellowBtn_192x47,
  GreenBtn_237x47,
  PinkBtn_174x47,
  GreenBtn_228x47,
  YellowBtn_183x47,
  GreenBtn_75x47,
  CyanBtn_165x47,
  YellowBtn_156x47,
  GreenBtn_219x47,
  CyanBtn_174x47,
  PinkBtn_219x47,
  YellowBtn_291x47,
  PurpleBtn_246x47,
  GreenBtn_246x47,
  CyanBtn_138x47,
  PurpleBtn_309x47,
  CyanBtn_237x47,
  PinkBtn_156x47,
  GreenBtn_273x47,
  CyanBtn_201x47,
  PurpleBtn_147x47,
  GreenBtn_255x47,
  PurpleBtn_174x47,
  PurpleBtn_255x47,
  GreenBtn_147x47,
  CyanBtn_219x47,
}

// export icon's svg component
export {
  ArrowUpGrey_18x18,
  ArrowDownGrey_18x18,
  ArrowRightGrey_18x18,
  CloseGrey_18x18,
  DeleteGrey_24x24,
  DocumentWhite_16x16,
  InfoGrey_24x24,
  CircledBack,
  GreyDirectory_24x24,
  GreyDelete_18x18,
  LightningYellow_32x26,
  PolyDevice_48x48,
  PolyDirectory_48x48,
  RedDelete_18x18,
  WhitePolygon_20x20,
  YellowPlus_48x48,
  PlusRed_48x48,
  RedCheck_16x16,
  Drop_Down_White_14x14,
  Drop_Up_White_14x14,
  WorkGrey_24x24,
  PolygonGreyRedOutline_12x12,
  PolygonRedFill_16x16,
  EmojiCryWhite_24x24,
  SliderPrevGrey_24x24,
  SliderNextGrey_24x24,
  InfoRed_16x16,
  PolygonPulse_40x40,
  PolygonRedFill_16x18,
  PolygonGreyOutline_16x18,
  TwitterWhitePolygonBg_24x24,
  TimerRed_18x18,
  HexagonDownGrey_30x30,
  HexagonUpGrey_30x30,
  HexagonRingGrey_30x30,
  HexagonRingRed_30x30,
  HexagonEmpty_30x30,
  PolygonRedFill_18x20,
  PolygonGreyOutline_18x20,
  CloseGrey_24x24,
  RingRed_24x24,
  XClose_10x10,
  RedUpload_30x36,
  PolyDirectoryCyan_48x48,
  PolyDirectoryGreen_48x48,
  PolyDirectoryYellow_48x48,
  PolyDirectoryPurple_48x48,
  BrainGreyPolygonOutline_24x24,
  BrainWhite_24x24,
  PulseGrey_24x24,
  EditWhite_16x16,
  HeartWhite_16x16,
  DeleteWhite_16x16,
  PolygonHOutlineGrey_24x24,
  PolygonHRedFill_24x24,
  GraphGrey_24x24,
  HiveWhite_12x12,
  SliderSquareRight_24x24,
  SliderSquareLeft_24x24,
  CheckGreyIcon_16x16,
}

// export image
export {
  Logo_270x324,
  LogoStaticDesktop_64x64,
  Logo_42x42,
  Logo_30x36,
  FooterLogo_134x110,
}
