import mentionsAvatar1 from '@/assets/img/avatar/mentions_avatar1_36x32.svg'
import mentionsAvatar2 from '@/assets/img/avatar/mentions_avatar2_36x32.svg'

export const pulseData = {
  menuList: ['Surveillance', 'Spotlight', 'Signals'],
  subMenuList: [
    'Actuary',
    'Analyst',
    'Criminologist',
    'Researcher',
    'All Modes',
  ],
  SurveillanceMainMenu: ['Dashboard', 'Mentions', 'Alarms', 'Filters'],
}

export const pulseURL = {
  0: '/pulse/Surveillance',
  1: '/pulse/Spotlight',
  2: '/pulse/Signals',
}

const allMentionItem = {
  avatar: mentionsAvatar1,
  title: 'The Canadian Press',
  article:
    'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for the treatment of patients with advanced-stage cancer.',
}

const dirMentionItem = {
  avatar: mentionsAvatar2,
  title: 'The Canadian Press',
  article:
    'MindBio Therapeutics has received ethics approval for a Phase II trial of microdoses of Lysergic Acid Diethylamide (LSD) and Meaning Centred Psychotherapy for the treatment of patients with advanced-stage cancer.',
}

export const surveillanceMentions = {
  menuList: ['All Mentions', 'Direct Mentions', 'Abstract Mentions'],
  allMentions: [allMentionItem, allMentionItem, allMentionItem],
  directMentions: [dirMentionItem, dirMentionItem, dirMentionItem],
  abstractMentions: [allMentionItem, allMentionItem, allMentionItem],
}
