export const pluse_sportLight_actuary = [
  {
    title: 'Roche Holding AG',
    completing: 48,
  },
  {
    title: 'Roche Holding AG',
    completing: 37,
  },
  {
    title: 'Roche Holding AG',
    completing: 18,
  },
  {
    title: 'Roche Holding AG',
    completing: 73,
  },
  {
    title: 'Roche Holding AG',
    completing: 38,
  },
  {
    title: 'Roche Holding AG',
    completing: 39,
  },
  {
    title: 'Roche Holding AG',
    completing: 53,
  },
  {
    title: 'Roche Holding AG',
    completing: 48,
  },
  {
    title: 'Roche Holding AG',
    completing: 37,
  },
  {
    title: 'Roche Holding AG',
    completing: 18,
  },
  {
    title: 'Roche Holding AG',
    completing: 73,
  },
  {
    title: 'Roche Holding AG',
    completing: 38,
  },
  {
    title: 'Roche Holding AG',
    completing: 39,
  },
  {
    title: 'Roche Holding AG',
    completing: 53,
  },
  {
    title: 'Roche Holding AG',
    completing: 48,
  },
  {
    title: 'Roche Holding AG',
    completing: 37,
  },
  {
    title: 'Roche Holding AG',
    completing: 18,
  },
  {
    title: 'Roche Holding AG',
    completing: 73,
  },
  {
    title: 'Roche Holding AG',
    completing: 38,
  },
  {
    title: 'Roche Holding AG',
    completing: 39,
  },
  {
    title: 'Roche Holding AG',
    completing: 53,
  },
  {
    title: 'Roche Holding AG',
    completing: 48,
  },
  {
    title: 'Roche Holding AG',
    completing: 37,
  },
  {
    title: 'Roche Holding AG',
    completing: 18,
  },
  {
    title: 'Roche Holding AG',
    completing: 73,
  },
  {
    title: 'Roche Holding AG',
    completing: 38,
  },
  {
    title: 'Roche Holding AG',
    completing: 39,
  },
  {
    title: 'Roche Holding AG',
    completing: 53,
  },
  {
    title: 'Roche Holding AG',
    completing: 48,
  },
  {
    title: 'Roche Holding AG',
    completing: 37,
  },
  {
    title: 'Roche Holding AG',
    completing: 18,
  },
  {
    title: 'Roche Holding AG',
    completing: 73,
  },
  {
    title: 'Roche Holding AG',
    completing: 38,
  },
  {
    title: 'Roche Holding AG',
    completing: 39,
  },
  {
    title: 'Roche Holding AG',
    completing: 53,
  },
]
