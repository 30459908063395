import pricingIcon from '@/assets/img/navbar/navbar-pricing.svg'
import productIcon from '@/assets/img/navbar/navbar-product.svg'
import aboutIcon from '@/assets/img/navbar/navbar-about.svg'
import solutionIcon from '@/assets/img/navbar/navbar-solutions.svg'

export const navbarTestData = [
  {
    id: '1',
    title: 'pricing',
    url: '/static/pricing',
    class: 'block',
    icon: pricingIcon,
  },
  {
    id: '2',
    title: 'product',
    url: '/',
    class: 'block',
    icon: productIcon,
  },
  {
    id: '3',
    title: 'solutions',
    url: '/static/solutions',
    class: 'block',
    icon: solutionIcon,
  },
  {
    id: '4',
    title: 'about us',
    url: '/static/about',
    class: 'block',
    icon: aboutIcon,
  },
  {
    id: '5',
    title: 'desktop app',
    url: '/static/desktop-app',
    class: 'block md:hidden',
    icon: aboutIcon,
  },
  {
    id: '6',
    title: 'AI Impact Index',
    url: 'https://impact.firesight.ai',
    class: 'block',
    icon: aboutIcon,
  },
  {
    id: '7',
    title: 'Blog',
    url: 'https://blog.firesight.ai/',
    class: 'block',
    icon: pricingIcon,
  },
]
