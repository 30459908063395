'use client' //this is a client side component

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isASRMode: false,
  newChatMessage: '',
  isReadMode: false,
  currentMode: {},
  userData: {},
  userRoles: [],
  knowledgeSessions: [],
  newGraphPrompt: '',
  curGraphInfo: {
    title: ''
  }
}

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setIsASRMode: (state, action) => {
      state.isASRMode = action.payload
    },
    setNewChatMessage: (state, action) => {
      state.newChatMessage = action.payload
    },
    setIsReadMode: (state, action) => {
      state.isReadMode = action.payload
    },
    setCurrentMode: (state, action) => {
      state.currentMode = action.payload
    },
    setUserData: (state, action) => {
      state.userData = action.payload
    },
    setUserRoles: (state, action) => {
      state.userRoles = action.payload
    },
    setKnowledgeSessions: (state, action) => {
      state.knowledgeSessions = action.payload
    },
    setNewGraphPrompt: (state, action) => {
      state.newGraphPrompt = action.payload
    },
    setCurGraphInfo: (state, action) => {
      state.curGraphInfo = action.payload
    }
  },
})

export const {
  setIsASRMode,
  setNewChatMessage,
  setIsReadMode,
  setCurrentMode,
  setUserData,
  setUserRoles,
  setKnowledgeSessions,
  setNewGraphPrompt,
  setCurGraphInfo
} = headerSlice.actions

export default headerSlice.reducer
