import Image from 'next/image'

import styles from './Button.module.css'
import clsx from 'clsx'

const CognisantButton = ({
  bgImage,
  mobileBgImage,
  className,
  onClick,
  bgClass,
  children,
}) => {
  return (
    <div
      onClick={onClick}
      className={clsx(styles.congnisant_button, className)}
    >
      <div className="hidden md:block">
        <Image
          src={bgImage}
          alt="background"
          width="auto"
          height="auto"
          className={bgClass}
        />
      </div>
      <div className="block md:hidden">
        <Image
          src={mobileBgImage ? mobileBgImage : bgImage}
          alt="background"
          width="100%"
          height="100%"
          className={bgClass}
        />
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  )
}

export default CognisantButton
