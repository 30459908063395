import DashboardGrey from '@/assets/img/icons/dashboard_grey_24x24.svg'
import Dashboard from '@/assets/img/icons/dashboard_white_24x24.svg'
import ChatGrey from '@/assets/img/icons/hive_grey_24x24.svg'
import Chat from '@/assets/img/icons/hive_white_24x24.svg'
import WrokGrey from '@/assets/img/icons/work_grey_24x24.svg'
import Work from '@/assets/img/icons/work_white_24x24.svg'
import NewsGrey from '@/assets/img/icons/news_grey_24x24.svg'
import News from '@/assets/img/icons/news_white_24x24.svg'
import GraphGrey from '@/assets/img/icons/graph_grey_24x24.svg'
import Graph from '@/assets/img/icons/graph_white_24x24.svg'
import PulseGrey from '@/assets/img/icons/pulse_grey_24x24.svg'
import Pulse from '@/assets/img/icons/pulse_white_24x24.svg'

export const bottomBarData = [
  {
    name: 'Dashboard',
    icon: DashboardGrey,
    activeIcon: Dashboard,
    basePath: '/dashboard',
    url: '/dashboard',
  },
  {
    name: 'Chat',
    icon: ChatGrey,
    activeIcon: Chat,
    basePath: '/chat',
    url: '/chat/chats',
  },
  {
    name: 'Work',
    icon: WrokGrey,
    activeIcon: Work,
    basePath: '/work',
    url: '/work/jobs',
  },
  {
    name: 'News',
    icon: NewsGrey,
    activeIcon: News,
    basePath: '/news',
    url: '/news/smart-on',
  },
  {
    name: 'Graph',
    icon: GraphGrey,
    activeIcon: Graph,
    basePath: '/graph',
    url: '/graph/queries?graphId=',
  },
  {
    name: 'Pulse',
    icon: PulseGrey,
    activeIcon: Pulse,
    basePath: '/pulse',
    url: '/pulse',
  },
]
